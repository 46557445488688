import Cookies from "js-cookie"

import { COOKIE_KEYS } from "~/lib/constants"
import { canUseDom } from "~/utils/can-use-dom"

export async function getReleaseId(): Promise<string | undefined> {
  if (!canUseDom) {
    return (await import("next/headers")).cookies().get(COOKIE_KEYS.RELEASE_ID)?.value
  } else {
    return Cookies.get(COOKIE_KEYS.RELEASE_ID)
  }
}
