"use client"

import { clsx } from "clsx"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import BadgeTag from "~/components/ui/BadgeTag"
import { useCardTags } from "~/managers/CardTagsManager/hooks"

import * as css from "./styles.css"

export type CardTagsProps = PropsWithClassName<{
  items: Nullish<string[]>
}>

function CardTags({ className, items }: CardTagsProps) {
  const cardTags = useCardTags() ?? []
  return (
    <div data-comp="UI/CardsTags" className={clsx(className, css.CardTags)}>
      {(items ?? [])?.map((tag, index) => {
        const cardTag = cardTags.find((cardTag) => cardTag?.slug === tag)
        if (!cardTag) return null

        return <BadgeTag key={index} {...cardTag} />
      })}
    </div>
  )
}

export default CardTags
