import { useMutation, type UseMutationOptions } from "@tanstack/react-query"
import { useSetAtom } from "jotai"
import Cookies from "js-cookie"

import { COOKIE_KEYS } from "~/lib/constants"
import { nostoAppsClient } from "~/lib/nosto/clients/private"
import { CREATE_SESSION } from "~/lib/nosto/mutations/session/create"
import type { CreateSessionMutationResponse, CreateSessionMutationVariables } from "~/lib/nosto/mutations/session/types"
import useLocale from "~/hooks/useLocale"
import { nostoSessionAtom } from "~/components/globals/NostoSessionHandler"

export function useNostoCreateSession(mutationOptions?: UseMutationOptions<CreateSessionMutationResponse>) {
  const locale = useLocale()

  const sessionId = Cookies.get(COOKIE_KEYS.NOSTO_SESSION_ID)
  const setSessionId = useSetAtom(nostoSessionAtom)
  const client = nostoAppsClient({ locale })

  return useMutation<CreateSessionMutationResponse>({
    mutationFn: async () => {
      if (sessionId || !client) {
        return { newSession: sessionId }
      }

      const referer = document?.referrer ?? ""

      return await client
        .request<CreateSessionMutationResponse, CreateSessionMutationVariables>(CREATE_SESSION, {
          referer,
        })
        .catch(() => ({ newSession: null }))
    },
    ...(mutationOptions ?? {}),
    onSuccess: ({ newSession }, variables, context) => {
      if (newSession) {
        setSessionId(newSession)
        Cookies.set(COOKIE_KEYS.NOSTO_SESSION_ID, newSession)
        mutationOptions?.onSuccess?.({ newSession }, variables, context)
      }
    },
  })
}
