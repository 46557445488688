import { env } from "~/env"
import jwtDecode from "jwt-decode"

import { DEFAULT_LANG, DEFAULT_LOCALE, DEFAULT_MARKET } from "~/lib/i18n/constants"
import type { I18nLang, Markets } from "~/lib/i18n/types"
import { getLang, getMarket } from "~/lib/i18n/utils/get-i18n"
import { filledObject } from "~/utils/filled-object"

type NostoCredentials = {
  accountId: string
  appsToken: string
}

type NostoMarketCredentials = Record<I18nLang, NostoCredentials>

type NostoMarketsCredentials = Record<Markets, NostoMarketCredentials> | null

function getCurrentLangCredentials(marketCredentials: NostoMarketCredentials, locale: string) {
  // Retrieve current lang based on locale
  const lang = (getLang(locale) ?? DEFAULT_LANG) as I18nLang

  // Retrieve lang credentials and fallback on default lang
  const localizedCredentials = marketCredentials?.[lang] ?? marketCredentials?.[DEFAULT_LANG]

  // Check if the credentials are truthy and if the object is filled (that no properties are missing)
  if (!localizedCredentials || !filledObject<NostoCredentials>(localizedCredentials)) {
    return null
  }

  // Return found credentials
  return localizedCredentials
}

function getCurrentMarketCredentials(globalCredentials: NostoMarketsCredentials, locale: string) {
  // Retrieve current market based on locale
  const market = (getMarket(locale ?? DEFAULT_LOCALE) ?? DEFAULT_MARKET) as Markets

  // Retrieve market credentials and fallback on default market
  const marketCredentials = globalCredentials?.[market] ?? globalCredentials?.[DEFAULT_MARKET as Markets]

  // Check if the credentials are truthy and if the object is filled (that no properties are missing)
  if (!marketCredentials || !filledObject<NostoMarketCredentials>(marketCredentials)) {
    return null
  }

  // Return found credentials
  return marketCredentials
}

export function getNostoCredentials(locale: string): NostoCredentials | null {
  const token = env.NEXT_PUBLIC_NOSTO_MARKETS_CREDENTIALS

  // Object with all markets & langs Nosto credentials
  // (Each combination of market+lang is a separate Nosto account with specific credentials)
  const globalCredentials: NostoMarketsCredentials = token ? jwtDecode(token) : null

  // If there are no credentials set
  if (!globalCredentials) {
    // Stop the process
    return null
  }

  const marketCredentials = getCurrentMarketCredentials(globalCredentials, locale)

  if (!marketCredentials) {
    return null
  }

  const credentials = getCurrentLangCredentials(marketCredentials, locale)

  if (!credentials) {
    return null
  }

  return credentials
}
