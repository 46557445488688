import { COLORS_VALUE } from "~/lib/shopify/constants"
import { objectKeys } from "~/utils/object-keys"

const FALLBAK_COLOR = COLORS_VALUE.Noir

export function getProductColorByValue(value: string) {
  if (!objectKeys(COLORS_VALUE).includes(value)) return FALLBAK_COLOR

  return COLORS_VALUE[value as keyof typeof COLORS_VALUE]
}
