"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import Icon, { type IconProps } from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

type CloseButtonProps = Partial<Pick<IconProps, "theme" | "width">> & PropsWithClassName<ComponentProps<"button">>

function CloseButton({ className, theme = "black", width = 15, ...rest }: CloseButtonProps) {
  const t = useTranslate()

  return (
    <button className={clsx(className)} aria-label={t("aria_close")} {...rest}>
      <Icon name="Close" theme={theme} width={width} />
    </button>
  )
}

export default CloseButton
