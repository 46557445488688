"use client"

import { forwardRef, useEffect, useState, type ComponentProps, type LegacyRef } from "react"
import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { useVideo } from "~/components/abstracts/Video/VideoProvider"

import * as css from "../styles.css"

export type VideoPlayPauseButtonProps = PropsWithClassName<
  {
    playedChildren: React.ReactNode
    pausedChildren: React.ReactNode
    ariaLabel?: {
      play: string
      pause: string
    }
  } & ComponentProps<"button">
>

function VideoPlayPauseButton(
  { className, playedChildren, pausedChildren, children, ariaLabel, ...rest }: VideoPlayPauseButtonProps,
  ref?: LegacyRef<HTMLButtonElement>
) {
  const { videoRef } = useVideo()
  const [playing, setPlaying] = useState(false)

  const onPlay = () => {
    if (videoRef.current) {
      setPlaying(true)
    }
  }

  const onPause = () => {
    if (videoRef.current) {
      setPlaying(false)
    }
  }

  useEffect(() => {
    videoRef.current?.addEventListener("play", onPlay)
    videoRef.current?.addEventListener("pause", onPause)

    return () => {
      videoRef.current?.removeEventListener("play", onPlay)
      videoRef.current?.removeEventListener("pause", onPause)
    }
  }, [])

  const handleOnPlayPause = () => {
    if (videoRef.current?.paused || videoRef.current?.ended) {
      videoRef.current.play()
    } else {
      videoRef.current?.pause()
    }
  }

  return (
    <button
      data-comp="Abstracts/VideoPlayPauseButton"
      ref={ref}
      className={clsx(className, css.button)}
      onClick={handleOnPlayPause}
      aria-pressed={playing}
      aria-label={playing ? ariaLabel?.pause ?? "pause" : ariaLabel?.play ?? "play"}
      {...rest}
    >
      {playing ? pausedChildren : playedChildren}
      {children}
    </button>
  )
}

export default forwardRef<HTMLButtonElement, VideoPlayPauseButtonProps>(VideoPlayPauseButton)
