import type { UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { getLocations } from "~/lib/i18n/utils/get-i18n"

export default function getVariantAvailabilityFromMarket(variant: UnlikelyVariant, locale: Nullish<string>) {
  if (!locale) return 0

  const locations = getLocations(locale)

  const globalCount = variant?.quantityAvailable ?? 0

  const quantityAvailableWithLocation =
    variant?.storeAvailability?.reduce((acc, current) => {
      if (current?.location?.name && !locations.includes(current?.location?.name)) {
        acc -= current?.quantityAvailable ?? 0
      }
      return acc
    }, globalCount) ?? globalCount
  return quantityAvailableWithLocation
}
