declare global {
  interface Window {
    DocumentTouch: any
  }
}

const isTouchScreen: boolean =
  !!(
    typeof window !== "undefined" &&
    ("ontouchstart" in window ||
      (window.DocumentTouch && typeof document !== "undefined" && document instanceof window.DocumentTouch))
  ) || !!(typeof navigator !== "undefined" && navigator.maxTouchPoints)

export default isTouchScreen
