import { gql } from "graphql-request"

const BASE_FRAGMENT = `resultId
  resultTitle
  primary {
    productId
  }`

export const NOT_FOUND_FRAGMENT = `
forNotFoundPage(params: {
  isPreview: false, imageVersion:  VERSION_8_400_400
} ) {
  ${BASE_FRAGMENT}
}`

export const CART_FRAGMENT = `
forCartPage(params: {
  isPreview: false, imageVersion:  VERSION_8_400_400
}, value: 100) {
  ${BASE_FRAGMENT}
}`

export const UPDATE_PRODUCT_PAGE = gql`
  mutation UpdateSession(
    $sessionId: String!
    $event: InputEvent!
    $customer: InputCustomerInfoEntity
    $entityRef: String!
    $cart: InputCart
    $skipEvents: Boolean
  ) {
    updateSession(by: BY_CID, id: $sessionId, params: { cart: $cart, event: $event, customer: $customer, skipEvents: $skipEvents}) {
      pages {
        ${NOT_FOUND_FRAGMENT}
        ${CART_FRAGMENT}
        forProductPage(
          params: { isPreview: false, imageVersion: VERSION_8_400_400 }
          product: $entityRef
        ) {
         ${BASE_FRAGMENT}
        }
      }
    }
  }
`

export const UPDATE_CATEGORY_PAGE = gql`
mutation UpdateSession(
  $sessionId: String!
  $event: InputEvent!
  $customer: InputCustomerInfoEntity
  $entityRef: String!
  $cart: InputCart
  $skipEvents: Boolean
) {
  updateSession(by: BY_CID, id: $sessionId, params: { cart: $cart, event: $event, customer: $customer, skipEvents: $skipEvents }) {
    pages {
      ${NOT_FOUND_FRAGMENT}
      ${CART_FRAGMENT}
      forCategoryPage(params: {
        isPreview: false, imageVersion: VERSION_8_400_400
      }, category: $entityRef) {
        ${BASE_FRAGMENT}
      }
    }
  }
}
`

export const UPDATE_SEARCH_PAGE = gql`
mutation UpdateSession(
  $sessionId: String!
  $event: InputEvent!
  $customer: InputCustomerInfoEntity
  $entityRef: String!
  $cart: InputCart
  $skipEvents: Boolean
) {
  updateSession(by: BY_CID, id: $sessionId, params: { cart: $cart, event: $event, customer: $customer, skipEvents: $skipEvents }) {
    pages {
      ${NOT_FOUND_FRAGMENT}
      ${CART_FRAGMENT}
      forSearchPage(params: {
        isPreview: false, imageVersion:  VERSION_8_400_400
      }, term: $entityRef) {
        ${BASE_FRAGMENT}
      }
    }
  }
}
`

export const UPDATE_SESSION = gql`
  mutation UpdateSession(
    $sessionId: String!, 
    $event: InputEvent!, 
    $customer: InputCustomerInfoEntity, 
    $cart: InputCart
    $skipEvents: Boolean) {
    updateSession(by: BY_CID, id: $sessionId, params: { cart: $cart,event: $event, customer: $customer, skipEvents: $skipEvents }) {
      events {
        target
        type
      }
      pages {
        ${NOT_FOUND_FRAGMENT}
        ${CART_FRAGMENT}
      }
    }
  }
`
