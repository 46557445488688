import { useEffect, useRef } from "react"
import { useMutation } from "@tanstack/react-query"
import { useAtom, useAtomValue } from "jotai"

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import { nostoAppsClient } from "~/lib/nosto/clients/private"
import type {
  SessionEvent,
  UpdateSessionMutationResponse,
  UpdateSessionMutationVariables,
} from "~/lib/nosto/mutations/session/types"
import { getCustomerInput } from "~/lib/nosto/utils/get-customer-input"
import { getNostoFragmentFromEventType } from "~/hooks/nosto/useNostoUpdateSession/utils/get-nosto-session-fragment-from-event-type"
import { useGetNostoSessionCart } from "~/hooks/nosto/useNostoUpdateSession/utils/useGetNostoSessionCart"
import useLocale from "~/hooks/useLocale"
import { nostoSessionAtom, nostoSessionPagesAtom } from "~/components/globals/NostoSessionHandler"

export function useNostoUpdateSession() {
  const nostoSessionCart = useGetNostoSessionCart()
  const { data: customer, isSuccess } = useGetCustomer()
  const sessionId = useAtomValue(nostoSessionAtom)
  const locale = useLocale()
  const [_, setSessionPages] = useAtom(nostoSessionPagesAtom)

  const queuedEvents = useRef<SessionEvent[]>([])

  const client = nostoAppsClient({ locale })

  const { mutate: updateSession } = useMutation<
    UpdateSessionMutationResponse | null,
    unknown,
    Omit<UpdateSessionMutationVariables, "sessionId" | "entityRef">
  >({
    mutationFn: async ({ event, skipEvents = false }) => {
      if (!sessionId || !client) return null
      const customerPayload = getCustomerInput(customer)
      const nostoFragment = getNostoFragmentFromEventType(event.type)
      return await client
        .request<UpdateSessionMutationResponse, UpdateSessionMutationVariables>(nostoFragment, {
          sessionId,
          event,
          customer: customerPayload,
          entityRef: event?.target,
          cart: nostoSessionCart,
          skipEvents,
        })
        .catch(() => {
          return {
            updateSession: {
              events: [],
            },
          }
        })
    },
    onSuccess: (data) => {
      setSessionPages((prev) => ({ ...prev, ...data?.updateSession.pages }))
    },
  })

  useEffect(() => {
    if (isSuccess && sessionId && queuedEvents.current?.length > 0) {
      queuedEvents.current.forEach((event) => {
        updateSession({ event })
      })

      queuedEvents.current = []
    }
  }, [isSuccess, sessionId])

  return {
    mutate: ({ event, skipEvents }: { event: SessionEvent; skipEvents?: boolean }) => {
      if (!isSuccess && !sessionId) {
        queuedEvents.current.push(event)
      } else {
        updateSession({ event, skipEvents })
      }
    },
  }
}
