import i18nConfig from "~/config/i18n.json"
import getCurrency from "~/lib/get-currency"
import { getDefaultLocale } from "~/lib/i18n/utils/get-default"
import useLocale from "~/hooks/useLocale"
import { objectKeys } from "~/utils/object-keys"

export default function useCurrency() {
  const locale = useLocale()
  const i18nLocale = objectKeys(i18nConfig).find((key) => key === locale) ?? getDefaultLocale()
  return getCurrency(i18nLocale)
}
