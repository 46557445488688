"use client"

import { useState, type MouseEvent } from "react"
import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import type { InferReturn, Nullish, PropsWithClassName } from "~/@types/generics"
import { ColorSelector } from "~/components/ui/ColorSelector"
import { Link } from "~/components/ui/Link"
import type { serializeLinkedProducts } from "~/components/ui/ProductHeader/components/ProductDetails/components/LinkedProducts/_data/serializer"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type LinkedProductsProps = PropsWithClassName<
  {
    layout?: "large" | "light"
    colors: InferReturn<typeof serializeLinkedProducts>
    onClick?: (
      index: number,
      primaryColor: string,
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
    ) => void
    /**
     * Choose the wrapper component for the color element.
     * If "Link", the color will be wrapped in a Link component
     * If "button", the color will be wrapped in a button component
     */
    colorWrapperComponent?: "Link" | "button"
  } & RecipeVariants<typeof css.LinkedProducts>
>

function ClientLinkedProducts({
  className,
  colors,
  onClick,
  colorWrapperComponent = "Link",
  labelPosition = "top",
  layout = "large",
}: LinkedProductsProps) {
  const activeColor = colors.find((color) => color.active)
  const t = useTranslate()

  const [computedActiveColor, setComputedActiveColor] = useState<Nullish<string>>(activeColor?.value)

  const handleOnHover = (value: typeof computedActiveColor) =>
    value ? setComputedActiveColor(value) : setComputedActiveColor(activeColor?.value)

  if (!colors.length) return null

  // In case of layout === "large", we want to display all colors
  // In case of layout === "light", we want to display only 3 colors
  const lightLayoutColorsCount = 3
  const shouldSliceColors = layout === "light" && colors.length > lightLayoutColorsCount
  const colorsAdaptedToLayout = shouldSliceColors ? colors.slice(0, lightLayoutColorsCount) : colors
  const ColorWrapperComponent = colorWrapperComponent === "Link" ? Link : "button"

  const currentColorValue = colorsAdaptedToLayout?.[0]?.value

  return (
    <div
      onMouseOut={() => setComputedActiveColor(activeColor?.value)}
      className={clsx(css.LinkedProducts({ labelPosition }), className)}
    >
      {activeColor && layout === "large" && (
        <span className={clsx(css.activeColor, text({ design: "neue-13-12", color: "grey-tundora" }))}>
          {t("color")} : {computedActiveColor}
        </span>
      )}
      <div className={clsx(css.SelectorsContainer({ layout }))}>
        {colorsAdaptedToLayout.map(({ active, hexa, link, value }, key) => (
          <ColorWrapperComponent
            key={`product_color_${key}`}
            className={clsx(css.ColorSelector({ layout }))}
            aria-label={value}
            onClick={(e) => onClick?.(key, value, e)}
            {...(colorWrapperComponent === "Link" && link)}
          >
            <ColorSelector layout={layout} hexa={hexa} active={active} value={value} onHover={handleOnHover} />
          </ColorWrapperComponent>
        ))}
        {/** If we have more that 3 colors display a "+" button with a link to the initial product */}
        {shouldSliceColors && currentColorValue && (
          <ColorWrapperComponent
            key={`product_color_more`}
            className={clsx(css.ColorSelector({ layout }), css.moreColors)}
            onClick={(e) => onClick?.(0, currentColorValue, e)}
            {...(colorWrapperComponent === "Link" && colorsAdaptedToLayout?.[0]?.link)}
          >
            +
          </ColorWrapperComponent>
        )}
      </div>
    </div>
  )
}

export { ClientLinkedProducts }
