import type { ParseUrlParams } from "~/@types/generics"
import type { SbSlug } from "~/lib/storyblok/constants"
import trimSlug from "~/lib/storyblok/utils/trim-slug"
import { objectEntries } from "~/utils/object-entries"

export function getStoryPath<U extends SbSlug, Params extends ParseUrlParams<U>>(path: U, params: Params): string {
  return trimSlug(
    objectEntries(params).reduce((acc, [key, value]) => acc.replace(`:${String(key)}`, String(value)), path as string)
  )
}
