import { sfProducts } from "@unlikelystudio/commerce-connector"

import { LOCATIONS_COUNT } from "~/lib/shopify/constants/locations"
import { sfFetchWrapper } from "~/lib/shopify/utils/sf-fetch-wrapper"

export type IGetShopifyProducts = Awaited<ReturnType<typeof getSfProducts>>

export type IGetShopifyProductByHandleParams = Partial<Parameters<typeof get>[0]>

export async function getSfProducts(input: IGetShopifyProductByHandleParams) {
  return await get({
    first: 20,
    imagesFirst: 10,
    includeDescriptionHtml: true,
    includeSelectedOptions: true,
    includeQuantityAvailable: true,
    variantsFirst: 100,
    includePrices: true,
    storeAvailabilityFirst: LOCATIONS_COUNT,
    ...input,
  })
}

const get = sfFetchWrapper(sfProducts)("product")
