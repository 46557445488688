import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { TVariant } from "~/components/ui/ProductHeader/_data/types"

export function getDefaultVariant(variants: TVariant[], product: UnlikelyProduct) {
  return (
    variants?.find(
      (variant) => variant.price?.amount === product?.priceRange?.minVariantPrice?.amount && variant.availableForSale
    ) ??
    variants?.find((variant) => variant.price?.amount === product?.priceRange?.minVariantPrice?.amount) ??
    null
  )
}
