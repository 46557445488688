import { createRef } from "react"
import { useAtom, useSetAtom } from "jotai"

import { useMeasureObserverCustom } from "~/hooks/useMeasureObserverCustom"
import { collectionCardImageHeightAtom, collectionGridSizeAtom } from "~/managers/CollectionManager"

export function useCollectionGridSize() {
  return useAtom(collectionGridSizeAtom)
}

export function useCollectionCardImageHeight() {
  return useAtom(collectionCardImageHeightAtom)
}

export function useUpdateCollectionCardImageHeight<E extends HTMLElement = HTMLElement>(
  callback?: (height: number) => number
) {
  const setValue = useSetAtom(collectionCardImageHeightAtom)
  const [gridSize] = useCollectionGridSize()
  const ref = createRef<E>()
  const { height } = useMeasureObserverCustom(ref, "getBoundingClientRect", [gridSize])

  setValue(callback ? callback(height) : height)

  return { ref, setValue }
}
