import type { ReactNode } from "react"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import CloseButton from "~/components/ui/CloseButton"
import { usePopin } from "~/managers/PopinManager"

import * as css from "./styles.css"

export type PanelHeaderProps = PropsWithClassName<{
  title?: ReactNode
  onRemove?(): void
}>

function PopinHeader({ className, title, onRemove }: PanelHeaderProps) {
  const { removeCurrent } = usePopin()
  const handleOnClick = () => {
    onRemove?.()
    removeCurrent()
  }

  return (
    <header className={clsx(css.PopinHeader({ withTitle: Boolean(title) }), className)}>
      {title && <p>{title}</p>}
      <CloseButton className={clsx(css.close)} width={14} onClick={handleOnClick} />
    </header>
  )
}

export default PopinHeader
