import { useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"

import type { UnlikelyProduct, UnlikelyVariant } from "@unlikelystudio/commerce-connector"
import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import { COLOR_LABELS, productMetafields, type COLORS_VALUE } from "~/lib/shopify/constants"
import { useSfProductByHandleQuery } from "~/lib/shopify/hooks/useSfProductByHandleQuery"
import { useSfProductsQuery } from "~/lib/shopify/hooks/useSfProductsQuery"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { getProductColorByValue } from "~/lib/shopify/utils/get-product-color-by-value"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import { metafieldsToObject } from "~/lib/shopify/utils/metafields-to-object"
import useLocale from "~/hooks/useLocale"
import serializeImage from "~/components/ui/Image/_data/serializer"
import serializeLink from "~/components/ui/Link/_data/serializer"
import { serializeSfPrice } from "~/components/ui/Price/_data/serializer"
import { serializeVariants } from "~/components/ui/ProductHeader/_data/serialize-variant"
import { getProductTrackingDataWithCollections } from "~/providers/GTMTrackingProvider/utils/get-product-tracking-data-with-collections"
import { unlikelyProductToTrackingData } from "~/providers/GTMTrackingProvider/utils/unlikely-product-to-tracking-data"
import { variantToTrackingData } from "~/providers/GTMTrackingProvider/utils/variant-to-tracking-data"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useCollectionTree } from "~/managers/CollectionsTreeManager/hooks"
import { objectValues } from "~/utils/object-values"
import { uniqBy } from "~/utils/uniq"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

type useQuickBuyPopinArgs = {
  initialHandle: NonNullable<UnlikelyProduct["handle"]>
  initialVariantId: NonNullable<UnlikelyVariant["id"]>
}

export default function useQuickBuyPopin({ initialHandle, initialVariantId }: useQuickBuyPopinArgs) {
  const locale = useLocale()
  const t = useTranslate()
  const [currentHandle, setCurrentHandle] = useState<NonNullable<UnlikelyProduct["handle"]>>(initialHandle)
  const [currentVariantId, setCurrentVariantId] = useState<NonNullable<UnlikelyVariant["id"]>>(initialVariantId)
  const { data: customer } = useGetCustomer()
  const { data } = useSfProductByHandleQuery(
    { handle: currentHandle, locale },
    {
      keepPreviousData: true,
    }
  )

  const { data: fetchedTrackingData } = useQuery(
    [data?.productByHandle?.id],
    async () => (data?.productByHandle ? unlikelyProductToTrackingData(data?.productByHandle, locale) : null),
    {
      // Set StaleTime to revalidate data after 15 minutes
      staleTime: 15 * 60 * 1000,
    }
  )

  const productsRef = data?.productByHandle?.variants?.[0]?.sku?.split("\\")?.[0]

  const { data: associatedProductsData } = useSfProductsQuery(
    {
      locale,
      query: `${productsRef}*`,
      metafieldKeys: objectValues(getLocalizedMetafields(productMetafields, locale)),
    },
    {
      enabled: !!productsRef,
    }
  )

  function getColorLabel(label: keyof typeof COLORS_VALUE) {
    try {
      return t(COLOR_LABELS[label])
    } catch (error) {
      return label
    }
  }

  const colorOptions = useMemo(
    () =>
      uniqBy(
        [data?.productByHandle ?? [], associatedProductsData?.products ?? []]
          ?.flat()
          ?.map((product) => {
            const metafields =
              product.metafields &&
              metafieldsToObject(product.metafields, getLocalizedMetafields(productMetafields, locale))

            const label = metafields?.primaryColor as keyof typeof COLOR_LABELS

            if (!label || !product.handle) return null

            const hexa = getProductColorByValue(label)

            return {
              value: product.handle,
              label: getColorLabel(label),
              hexa,
              selected: product.handle === currentHandle,
            }
          })
          .filter(Boolean)
          .sort((a, b) => (a.label > b.label ? -1 : 1)),
        "value"
      ),
    [associatedProductsData?.products, currentHandle, data?.productByHandle, locale]
  )

  const { productByHandle } = data || { productByHandle: null }
  const variants = useMemo(
    () => serializeVariants(productByHandle?.variants ?? [], locale),
    [locale, productByHandle?.variants]
  )

  const currentVariant = useMemo(
    () => variants?.find((variant) => variant.id === currentVariantId) ?? variants?.[0],
    [variants, currentVariantId]
  )

  const variantOptions = useMemo(
    () =>
      variants
        ?.map((variant) => {
          if (!variant.id || !variant.size) return null

          return {
            value: variant.id,
            label: variant.size,
            disabled: !variant.availableForSale,
            selected: variant.id === currentVariantId,
          }
        })
        .filter(Boolean),
    [variants, currentVariantId]
  )

  const { processedPrice, processedCompareAtPrice } = processVpPrice({
    price: currentVariant?.price,
    compareAtPrice: currentVariant?.compareAtPrice,
    vpPrice: currentVariant?.vpPrice,
    customer,
  })

  const sfPrice = serializeSfPrice(locale, processedPrice, processedCompareAtPrice)
  const rawPrice = processedPrice
  const title = productByHandle?.title
  const image = serializeImage(productByHandle?.images?.[0], locale)
  const link =
    productByHandle?.id && productByHandle?.handle
      ? serializeLink({ id: productByHandle?.id, handle: productByHandle?.handle }, locale)
      : null

  const [collectionTree] = useCollectionTree()
  const trackingData =
    data?.productByHandle && currentVariant && fetchedTrackingData
      ? {
          ...getProductTrackingDataWithCollections(
            fetchedTrackingData,
            data?.productByHandle.collections.map((collection) => collection.handle).filter(Boolean),
            collectionTree
          ),

          ...(variantToTrackingData(currentVariant ?? null) ?? {}),
        }
      : null

  return {
    currentHandle,
    setCurrentHandle,
    currentVariant: currentVariant ?? null,
    currentVariantId,
    setCurrentVariantId,
    rawPrice,
    price: sfPrice,
    title,
    image,
    link: { ...link, href: `${link?.href}?variant=${getReferenceIdFromGID(currentVariantId)}` },
    colorOptions,
    variantOptions,
    trackingData,
  }
}
