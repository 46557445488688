"use client"

import { useEffect } from "react"
import { useInView } from "framer-motion"

import type { PropsWithClassName } from "~/@types/generics"
import { useVideo } from "~/components/abstracts/Video/VideoProvider"

function Video({ className }: PropsWithClassName) {
  const { videoRef, sources, autoPlay, ...videoProps } = useVideo()
  const inView = useInView(videoRef)

  useEffect(() => {
    if (inView && autoPlay) {
      videoRef?.current?.play()
    } else {
      videoRef?.current?.pause()
    }
  }, [inView])

  return (
    <video ref={videoRef} className={className} autoPlay={false} {...videoProps}>
      {sources.map((source) => (
        <source key={source.src} {...source} />
      ))}
    </video>
  )
}

export default Video
