import type { UnlikelyVideo } from "@unlikelystudio/commerce-connector"

import type { TVideo } from "~/components/ui/Video"

export function serializeVideo(item: UnlikelyVideo): TVideo {
  return {
    poster: item?.poster?.url ?? undefined,
    sources: item.sources.map((source) => ({ src: source.url, width: source.width, height: source.height })),
  }
}

export function serializeVideos(items: UnlikelyVideo[]): TVideo[] {
  return items?.map((video) => serializeVideo(video)) ?? []
}
