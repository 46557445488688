import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish } from "~/@types/generics"
import { getSelectedOption } from "~/lib/shopify/utils/get-selected-option"
import getVariantAvailabilityFromMarket from "~/lib/shopify/utils/get-variant-availibility-from-market"
import serializeImage from "~/components/ui/Image/_data/serializer"

export type TBackInStock = InferReturn<typeof serializeSfBackInStock>

export function serializeSfBackInStock(data: Nullish<UnlikelyProduct>, locale: Nullish<string>) {
  if (!data) return null

  const { id, images, variants } = data
  if (!variants?.length || !id) return null

  return {
    productId: id,
    image: serializeImage(images?.[0], locale) ?? null,
    variants: variants
      .filter((variant) => {
        const availabilityFromMarket = getVariantAvailabilityFromMarket(variant, locale)
        return availabilityFromMarket === 0
      })
      .map((variant) => ({
        id: variant.id,
        title: getSelectedOption(variant, "size"),
        price: variant.price,
      }))
      .filter(({ title }) => Boolean(title)),
  }
}
