"use client"

import clsx from "clsx"

import createStackComponentManager, { type ElementProps } from "~/components/abstracts/StackComponentManager"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

const [PopinManager, AbstractPopin, usePopin] = createStackComponentManager()

type PopinProps = ElementProps & {
  zIndex?: Sprinkles["zIndex"]
  bgColor?: Sprinkles["backgroundColor"]
}

export function Popin({ zIndex = 50, bgColor = "black/50", className, ...props }: PopinProps) {
  return (
    <AbstractPopin
      className={clsx(sprinkles({ zIndex, bgColor, inset: 0, position: "fixed" }), className)}
      {...props}
    />
  )
}

export { PopinManager, usePopin }
