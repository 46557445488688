import { useEffect } from "react"
import { useQuery, type UseQueryOptions } from "@tanstack/react-query"

import type { Nullish } from "~/@types/generics"
import { getProductQuickbuyVariants } from "~/lib/shopify/utils/get-product-quickbuy-variants"
import useLocale from "~/hooks/useLocale"
import type { serializeSfQuickBuy } from "~/components/ui/ProductCardSlider/Layout/QuickBuy/_data/serializer"

type Return = Awaited<ReturnType<typeof serializeSfQuickBuy>>

type QueryOptions = {
  throwError?: boolean
  onError?(error: Error): void
  onSuccess?(data: Return): void
} & Omit<UseQueryOptions<Return, Error, Return>, "queryFn" | "onError">
export function useGetQuickbuyVariants(handle: Nullish<string>, queryOptions?: QueryOptions) {
  const locale = useLocale()
  const { queryKey = [], ...options } = queryOptions ?? {}

  const response = useQuery<Return, Error, Return>({
    queryKey: ["fetchQuickbuyVariants", handle, ...queryKey],
    queryFn: async () => await getProductQuickbuyVariants(handle, locale),
    ...options,
  })

  useEffect(() => {
    switch (response.status) {
      case "error":
        options?.onError?.(response.error)
        break
      case "success":
        options?.onSuccess?.(response.data)
        break
      default:
        break
    }
  }, [response.status])

  return response
}
