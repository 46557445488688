import { useQuery } from "@tanstack/react-query"

import type { InferReturn } from "~/@types/generics"
import { getSfProductByHandle } from "~/lib/shopify/queries/get-sf-product-by-handle"

type TData = InferReturn<typeof getSfProductByHandle>

export function useSfProductByHandleQuery<TError = unknown>(
  params: Parameters<typeof getSfProductByHandle>[0],
  options?: Parameters<typeof useQuery<TData, TError, TData, [Parameters<typeof getSfProductByHandle>[0]]>>[2]
) {
  return useQuery<TData, TError, TData, [Parameters<typeof getSfProductByHandle>[0]]>(
    [params],
    async () => await getSfProductByHandle(params),
    {
      // Set StaleTime to revalidate data after 15 minutes
      staleTime: 15 * 60 * 1000,
      ...options,
    }
  )
}
