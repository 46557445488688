import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { BRAND_NAME } from "~/lib/shopify/constants"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import serializeVariantsSelector from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductVariantsSelector/_data/serializer"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import { variantToTrackingData } from "~/providers/GTMTrackingProvider/utils/variant-to-tracking-data"

export function unlikelyProductToTrackingData(product: UnlikelyProduct, locale: Nullish<string>): CartLineItemPayload {
  const { defaultVariant } = serializeVariantsSelector(product, locale)

  const compareAtPrice = product.priceRange?.minVariantPrice?.amount
  const productSku = defaultVariant?.sku?.split("\\")[0]

  return {
    item_name: product?.title ?? "",
    item_id: productSku ?? "",
    item_product_id: String(getReferenceIdFromGID(product?.id)) ?? null,
    price: product.priceRange?.minVariantPrice?.amount ?? 0,
    affiliation: "Headless",
    description: product.seo?.description ?? "",
    item_brand: BRAND_NAME,
    quantity: 1,
    discount: compareAtPrice ? compareAtPrice - (product.compareAtPriceRange?.minVariantPrice?.amount ?? 0) : 0,
    ...(variantToTrackingData(defaultVariant) ?? {}),
  }
}
