"use client"

import { useAtomValue } from "jotai"

import { cardTagsAtom } from "~/managers/CardTagsManager/store"

import "~/managers/InternalRedirectsManager/ClientInternalRedirectsManager"

export function useCardTags() {
  return useAtomValue(cardTagsAtom)
}
