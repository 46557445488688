"use client"

import { type Dispatch, type SetStateAction } from "react"
import { clsx } from "clsx"
import { useInView } from "react-intersection-observer"

import type { UnlikelyMoney, UnlikelyProduct } from "@unlikelystudio/commerce-connector"
import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import type { COLORS_VALUE } from "~/lib/shopify/constants"
import { useSfProductByHandleQuery } from "~/lib/shopify/hooks/useSfProductByHandleQuery"
import { getProductColorByValue } from "~/lib/shopify/utils/get-product-color-by-value"
import useLocale from "~/hooks/useLocale"
import serializeLink from "~/components/ui/Link/_data/serializer"
import Price from "~/components/ui/Price"
import { serializeSfPrice } from "~/components/ui/Price/_data/serializer"
import type { TVPPrice } from "~/components/ui/ProductHeader/_data/serialize-vp-price"
import { sortLinkedProducts } from "~/components/ui/ProductHeader/components/ProductDetails/components/LinkedProducts/_data/serializer"
import { ClientLinkedProducts } from "~/components/ui/ProductHeader/components/ProductDetails/components/LinkedProducts/component"
import { useLinkedProducts } from "~/components/ui/ProductHeader/components/ProductDetails/components/LinkedProducts/hooks/useLinkedProducts"
import { ClientTranslate } from "~/providers/I18nProvider/ClientTranslate"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import * as css from "./styles.css"

export type GroupMtc = { mtc: string; color: string }[]
export type FooterProps = PropsWithClassName<{
  title: string
  handle?: string
  price: UnlikelyMoney
  compareAtPrice: Nullish<UnlikelyMoney>
  vpPrice: Nullish<TVPPrice>
  groupMtc?: GroupMtc
  setPrimaryColor?: Dispatch<
    SetStateAction<{
      colorHref: string | undefined
      primaryColor: string | null | undefined
    }>
  >
  primaryColor?: string | null
  colorHref?: string
  productId: UnlikelyProduct["id"]
}>

function Footer({
  className,
  title,
  handle,
  price,
  compareAtPrice,
  vpPrice,
  productId,
  primaryColor,
  colorHref,
  isOutOfStock,
  groupMtc,
  setPrimaryColor,
}: FooterProps & {
  isOutOfStock: boolean
}) {
  const locale = useLocale()
  const { data: customer } = useGetCustomer()
  const { data } = useSfProductByHandleQuery(
    { handle: handle ?? "", locale },
    {
      enabled: !!handle,
    }
  )
  const product = data?.productByHandle

  // Serialize the linked products metafields to get the colors initial data during page build

  // We need to display the current color first in the color selector
  const currentColor = primaryColor
    ? [
        {
          link: productId && handle ? serializeLink({ id: productId, handle }, locale) : null,
          value: primaryColor as keyof typeof COLORS_VALUE,
          active: true,
          hexa: getProductColorByValue(primaryColor),
        },
      ]
    : []
  // Then the other linked colors
  const linkedColorsInitialData =
    groupMtc?.map(({ color }) => ({
      link: null,
      value: color as keyof typeof COLORS_VALUE,
      active: false,
      hexa: getProductColorByValue(color),
    })) ?? []

  const initialData = [...currentColor, ...linkedColorsInitialData]
  // We need to sort the colors the same way they are sorted when we fetch them on hover and remove duplicates
  const sortedInitialData = initialData.filter((item, index) => initialData.indexOf(item) === index)
  /**
   * We need to fetch the linked products when the user is hovering the product card
   * or when the product card is in the viewport on touch devices because the hover event doesn't work on touch devices
   */
  const { inView, ref } = useInView({ initialInView: false })
  const shouldFetchLinkedProducts = inView

  // We are fetching the linked products only when the user is hovering the product card
  const { data: linkedProductsData } = useLinkedProducts({
    productKey: productId,
    locale,
    product,
    queryOptions: { enabled: shouldFetchLinkedProducts },
  })

  // Decide if we should use the initial product props comming from the current product metafield
  // Or the linked product props coming from the request made on hover
  const colors = linkedProductsData ?? sortedInitialData

  // We need to recompute the active color when the product is fetched because it's based on the product initial color
  const colorsWithDynamicActiveColor = sortLinkedProducts(
    colors.map((color) => ({
      ...color,
      active: color.link?.href === colorHref,
    }))
  )

  const { processedPrice, processedCompareAtPrice } = processVpPrice({
    price,
    compareAtPrice,
    vpPrice,
    customer,
  })

  const sfPrice = serializeSfPrice(locale, processedPrice, processedCompareAtPrice)

  return (
    <div className={clsx(className, css.Footer)}>
      {/**
       * Render a dummy element to trigger the inView observer.
       * This is needed to fetch the linked products only when hover event doesn't work.
       */}
      <span ref={ref} className={css.loadLinkedProductsOnTouchDevice} />
      <span className={clsx(css.title)}>{title}</span>
      <div>
        {sfPrice && <Price className={clsx(css.price)} {...sfPrice} />}
        {isOutOfStock && (
          <span className={css.outOfStock}>
            <ClientTranslate as="span" tKey="out_of_stock" />
          </span>
        )}
      </div>
      {colorsWithDynamicActiveColor.length > 0 && (
        <ClientLinkedProducts
          onClick={(index, primaryColor, e) => {
            e.preventDefault()
            setPrimaryColor?.({ primaryColor, colorHref: colorsWithDynamicActiveColor[index]?.link?.href })
          }}
          className={css.colors}
          colorWrapperComponent="button"
          layout="light"
          colors={colorsWithDynamicActiveColor}
        />
      )}
    </div>
  )
}

export default Footer
