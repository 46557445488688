"use client"

import { useEffect } from "react"
import { usePathname } from "next/navigation"
import { atom, useAtom, useAtomValue } from "jotai"

import type { UpdateSessionMutationResponse, UpdateSessionMutationVariables } from "~/lib/nosto/mutations/session/types"
import type { RecommendationsSlotIds } from "~/lib/nosto/recommendations/constants"
import { useNostoCreateSession } from "~/hooks/nosto/useNostoCreateSession"
import { useNostoUpdateSession } from "~/hooks/nosto/useNostoUpdateSession"

const idAtom = atom<string | null>(null)

export const nostoSessionAtom = atom(
  (get) => get(idAtom),
  (_, set, id: string) => {
    set(idAtom, id)
  }
)

export const nostoRefAtom = atom<RecommendationsSlotIds | null>(null)
export const nostoSessionPagesAtom = atom<UpdateSessionMutationResponse["updateSession"]["pages"] | null>(null)

export function NostoSessionHandler({
  target,
  type,
  skipEvents = false,
}: Partial<UpdateSessionMutationVariables["event"] & Pick<UpdateSessionMutationVariables, "skipEvents">>) {
  const sessionId = useAtomValue(nostoSessionAtom)
  const [ref, setRef] = useAtom(nostoRefAtom)
  const pathname = usePathname()

  const processTarget = target ?? pathname

  const { mutate: createSession } = useNostoCreateSession()
  const { mutate: updateSession } = useNostoUpdateSession()

  useEffect(() => {
    if (sessionId && type) {
      updateSession({ event: { target: processTarget, type, ref }, skipEvents })
      if (ref) {
        setRef(null)
      }
    }
  }, [sessionId, type, processTarget])

  useEffect(() => {
    createSession()
  }, [])

  return null
}
