"use client"

import { createRef, type MouseEvent } from "react"
import { clsx } from "clsx"
import { useHover } from "usehooks-ts"

import type { UseDragParams } from "@unlikelystudio/react-hooks"
import SliderImages, { useSliderState } from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import { FillImage } from "~/components/ui/FillImage"
import { type ImageProps } from "~/components/ui/Image"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import Video, { type TVideo } from "~/components/ui/Video"
import Icon from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useUpdateCollectionCardImageHeight } from "~/managers/CollectionManager"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

const IMAGE_RATIO = "74_110" satisfies Sprinkles["ratio"]

type SliderMedia =
  | {
      type: "video"
      data: TVideo
    }
  | {
      type: "image"
      data: TImage
    }
export type SliderProps = PropsWithClassName<{
  widthClassName?: string
  medias: SliderMedia[]
  sizes?: ImageProps["sizesFromBreakpoints"]
  ratio?: ImageProps["ratio"]
  priority?: ImageProps["priority"]
  fill?: boolean
  outOfStock?: boolean
  withImageHeight?: boolean
  dragProps?: UseDragParams
}>

type ImageHeightCalculatorProps = PropsWithClassName<{}>

function ImageHeightCalculator({ className }: ImageHeightCalculatorProps) {
  const fixRoundError = 2
  const { ref } = useUpdateCollectionCardImageHeight<HTMLDivElement>((height) => height - fixRoundError)
  return <div ref={ref} className={className} />
}

function Slider({
  className,
  sizes,
  ratio,
  priority = false,
  widthClassName,
  outOfStock,
  medias,
  withImageHeight,
  fill = false,
  dragProps,
}: SliderProps) {
  const t = useTranslate()
  const [{ slideIndex, prevSlide, nextSlide }, setSliderState] = useSliderState()
  const ref = createRef<HTMLDivElement>()
  const isHover = useHover(ref)

  const handlePrevSlide = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    prevSlide()
  }

  const handleNextSlide = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    nextSlide()
  }

  return (
    <div ref={ref} className={clsx(css.Slider, className, widthClassName, { [css.outOfStock]: outOfStock })}>
      {withImageHeight && <ImageHeightCalculator className={css.imageHeightMock} />}
      <SliderImages
        dragProps={{ ...(dragProps ? dragProps : {}) }}
        className={clsx(css.imagesSlider, fill && sprinkles({ height: "100%" }))}
        containerClassName={fill ? sprinkles({ height: "100%" }) : undefined}
        setSliderState={setSliderState}
        maxSlideIndexChange={1}
        snap
      >
        {medias.map((media, index) => {
          switch (media.type) {
            case "video": {
              return <Video key={index} className={clsx(widthClassName, css.image, css.video)} {...media.data} fill />
            }

            case "image":
              return (
                <FillImage
                  key={index}
                  className={clsx(widthClassName, css.image)}
                  sizesFromBreakpoints={sizes}
                  ratio={fill ? undefined : ratio ?? IMAGE_RATIO}
                  priority={index === 0 && priority}
                  {...media.data}
                  asPlaceholder={index !== 0 || (index !== 0 && !priority)}
                />
              )
          }
        })}
      </SliderImages>
      {
        <div className={clsx(css.navigation({ active: isHover }))}>
          <button
            className={clsx(css.navigationButton({ disabled: slideIndex === 0 }))}
            type="button"
            aria-label={t("aria_arrow_prev")}
            onClick={handlePrevSlide}
          >
            <Icon name="ArrowSliderLeft" height={16} />
          </button>
          <button
            className={clsx(css.navigationButton({ disabled: slideIndex === medias.length - 1 }))}
            type="button"
            aria-label={t("aria_arrow_next")}
            onClick={handleNextSlide}
          >
            <Icon name="ArrowSliderRight" height={16} />
          </button>
        </div>
      }
    </div>
  )
}

export default Slider
