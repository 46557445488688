import type { SessionCart, SessionCartItem } from "~/lib/nosto/mutations/session/types"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import { useGetCart } from "~/hooks/useGetCart"

export function useGetNostoSessionCart() {
  const { cartPayload } = useGetCart()

  const nostoSessionCartItems = cartPayload?.lines?.map(
    (item) =>
      ({
        name: `${item.merchandise.product?.title} - ${item.merchandise.title}`,
        productId: getReferenceIdFromGID(item.merchandise.product?.id)?.toString() ?? null,
        quantity: item.quantity,
        unitPrice: item.merchandise.price?.amount ?? null,
        priceCurrencyCode: item.merchandise.price?.currencyCode ?? null,
        skuId: item.merchandise.sku,
      } satisfies SessionCartItem)
  )

  return {
    items: nostoSessionCartItems ?? [],
  } satisfies SessionCart
}
