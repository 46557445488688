export const globalInputPreset = {
  addressName: "addressName",
  city: "city",
  country: "country",
  email: "email",
  firstName: "firstName",
  fullName: "fullName",
  infoAddress: "infoAddress",
  lastName: "lastName",
  newPassword: "newPassword",
  password: "password",
  region: "region",
  repeatPassword: "repeatPassword",
  repeatNewPassword: "repeatNewPassword",
  search: "search",
  state: "state",
  streetName: "streetName",
  tags: "tags",
  tel: "tel",
  zipCode: "zipCode",
  message: "message",
  toggleCheckbox: "toggleCheckbox",
} as const

export type GlobalInputPreset = (typeof globalInputPreset)[keyof typeof globalInputPreset]
