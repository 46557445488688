import { getProductCollectionsTree } from "~/lib/storyblok/utils/get-product-collections-tree"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import type { TCollectionTree } from "~/managers/CollectionsTreeManager/_data/serializer"

export function getProductTrackingDataWithCollections(
  trackingData: CartLineItemPayload,
  collections: string[],
  collectionTree: TCollectionTree
) {
  if (!collectionTree) return trackingData

  // remove first 'Home page' collection
  const [, ...productCategories] =
    getProductCollectionsTree(collections, collectionTree)?.map((category) => category.label) ?? []

  return {
    ...trackingData,
    ...(productCategories
      ? productCategories?.reduce((acc, curr, index) => {
          return { ...acc, [`item_category${Boolean(index) ? `_${index + 1}` : ""}`]: curr }
        }, {})
      : {}),
  }
}
