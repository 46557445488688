import type { UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import { selectedOptions } from "~/lib/shopify/constants"

export function getSelectedOption(variant: UnlikelyVariant, key: keyof typeof selectedOptions) {
  return (
    variant?.selectedOptions?.find((option) => {
      return option?.name ? selectedOptions[key].includes(option?.name) : null
    })?.value ?? null
  )
}
