import { GraphQLClient } from "graphql-request"

import { getRevalidateTags, type RevalidateTag } from "~/lib/constants"
import { NOSTO_API_ENDPOINTS } from "~/lib/nosto/clients/constants"
import { getNostoCredentials } from "~/lib/nosto/clients/credentials"
import type { NostoClientOptions } from "~/lib/nosto/clients/types"
import { processedRevalidate } from "~/lib/vercel/constants"

export const nostoAppsClient = ({ locale, tags }: NostoClientOptions): GraphQLClient | null => {
  const credentials = getNostoCredentials(locale)

  if (!credentials?.appsToken) {
    return null
  }

  return new GraphQLClient(NOSTO_API_ENDPOINTS.APPS, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(":" + credentials?.appsToken),
    },
    fetch,

    next: { revalidate: processedRevalidate, tags: getRevalidateTags(...((tags ?? []) as RevalidateTag[])) },
  })
}
