"use client"

import { useEffect, useMemo, useState, type MouseEvent } from "react"
import { clsx } from "clsx"

import type { AllNonNullable, UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { formatCheckoutItems } from "~/lib/shopify/utils/format-checkout-items"
import { useAddToCart } from "~/hooks/useAddToCart"
import { useGetQuickbuyVariants } from "~/hooks/useGetQuickBuyVariants"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import type { TPrice } from "~/components/ui/Price"
import useQuickBuyPopin from "~/components/ui/QuickBuyPopin/hooks/useQuickBuyPopin"
import Spinner from "~/components/ui/Spinner"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export type QuickBuyProps = PropsWithClassName<{
  handle: string
  image: Nullish<TImage>
  title: string
  isHover: boolean
  price: Nullish<TPrice>
}>

function QuickBuy({ className, handle, isHover }: QuickBuyProps) {
  const t = useTranslate()

  const [hoverCount, setHoverCount] = useState<number>(0)
  useEffect(() => {
    if (isHover) {
      setHoverCount((prev) => prev + 1)
    }
  }, [isHover])

  const { data: buttons, isLoading } = useGetQuickbuyVariants(handle, {
    queryKey: [isHover],
    enabled: hoverCount === 0 && isHover,
  })

  const isHidden = buttons ? buttons?.length < 1 && !isLoading : false

  return (
    <div className={clsx(className, css.QuickBuy({ hidden: isHidden }))}>
      {isLoading ? (
        <Spinner className={clsx(css.spinner)} />
      ) : buttons && buttons?.length > 0 ? (
        <QuickBuyButtons items={buttons} handle={handle} />
      ) : (
        t("out_of_stock")
      )}
    </div>
  )
}

export default QuickBuy

export type QuickBuyButton = Pick<UnlikelyVariant, "title" | "id" | "availableForSale" | "quantityAvailable">

type QuickBuyButtonsProps = {
  isLoading?: boolean
  items: Nullish<QuickBuyButton[]>
  handle: string
}

function QuickBuyButtons({ items, isLoading, handle }: QuickBuyButtonsProps) {
  return items?.map(({ id, ...item }) =>
    id ? <QuickbuyButton key={id} isLoading={isLoading} handle={handle} id={id} {...item} /> : null
  )
}

type QuickBuyButtonProps = Omit<QuickBuyButton, "id"> &
  Pick<AllNonNullable<QuickBuyButton>, "id"> &
  Pick<QuickBuyButtonsProps, "isLoading" | "handle">

function QuickbuyButton({ handle, id, title, availableForSale, isLoading }: QuickBuyButtonProps) {
  const { currentVariant, trackingData, rawPrice } = useQuickBuyPopin({
    initialHandle: handle,
    initialVariantId: id,
  })

  const checkoutItems = useMemo(() => {
    if (!currentVariant || !trackingData) return []
    return formatCheckoutItems({ variant: currentVariant, trackingData })
  }, [currentVariant, JSON.stringify(trackingData)])

  const { mutate: addCartLines, isLoading: isAddingToCart } = useAddToCart({ id: currentVariant?.id, price: rawPrice })
  const handleOnClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (checkoutItems && checkoutItems?.length > 0) {
      addCartLines(checkoutItems)
    }
  }

  return isAddingToCart ? (
    <Spinner />
  ) : (
    <button
      className={clsx(css.item({ disabled: !availableForSale, visible: !isLoading }))}
      onClick={(e) => handleOnClick(e)}
      aria-label={title ?? undefined}
    >
      {title}
    </button>
  )
}
