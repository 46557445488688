"use client"

import { useRef } from "react"
import { clsx } from "clsx"
import { z } from "zod"

import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { useFetchSbStoryQuery } from "~/lib/storyblok/hooks/useFetchSbStoryQuery"
import { sbRichtextSchema } from "~/lib/storyblok/schemas/default/richtext"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import useLocale from "~/hooks/useLocale"
import { Wrapper } from "~/components/ui/BackInStockPopin/Wrapper"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import { Popin } from "~/managers/PopinManager"

import * as css from "./styles.css"

export type BackInStockVariant = Pick<TVariant, "price" | "id"> & { title: TVariant["size"] }

export type BackInStockProps = PropsWithClassName<{
  productId: UnlikelyProduct["id"]
  variants: BackInStockVariant[]
  defaultVariant?: TVariant["id"]
  image: Nullish<TImage>
}>

const globalBackInStockSchema = z.object({
  title: z.string().nullable(),
  paragraph: sbRichtextSchema().nullable(),
  privacy_policy_label: sbRichtextSchema().nullable(),
  success_title: z.string().nullable(),
})

function BackInStockPopin({ className, productId, image, variants, defaultVariant }: BackInStockProps) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const locale = useLocale()
  const globalBackInStock = useFetchSbStoryQuery(["globals/back-in-stock", { locale }, {}])
  const parsedGlobalBackInStock = handleZodSafeParse(
    globalBackInStockSchema,
    globalBackInStock?.data?.data.story.content,
    "BackInStockPopin"
  )
  const title = parsedGlobalBackInStock?.title ?? null
  const paragraph = parsedGlobalBackInStock?.paragraph ?? null
  const privacyPolicyLabel = parsedGlobalBackInStock?.privacy_policy_label ?? null
  const successTitle = parsedGlobalBackInStock?.success_title ?? null

  return (
    <Popin
      className={clsx(className, css.BackInStockPopin)}
      zIndex={50}
      bgColor="black/30"
      clickOutsideRef={wrapperRef}
    >
      <Wrapper
        ref={wrapperRef}
        productId={productId}
        title={title}
        successTitle={successTitle}
        variants={variants}
        image={image}
        defaultVariant={defaultVariant}
        paragraph={paragraph}
        privacyPolicyLabel={privacyPolicyLabel}
      />
    </Popin>
  )
}

export default BackInStockPopin
