import Cookies from "js-cookie"

import { COOKIE_KEYS } from "~/lib/constants"
import { canUseDom } from "~/utils/can-use-dom"

export async function getIsPreview(): Promise<boolean> {
  // Getting the preview mode if enabled
  if (!canUseDom) {
    const { isEnabled: isPreviewMode } = (await import("next/headers")).draftMode()
    return isPreviewMode
  } else {
    return Boolean(Cookies.get(COOKIE_KEYS.IS_DRAFT_MODE))
  }
}
