"use client"

import { forwardRef, type ComponentProps, type PropsWithChildren, type Ref } from "react"
import { clsx } from "clsx"

import type { Nullish } from "~/@types/generics"
import { FillImage } from "~/components/ui/FillImage"
import { type ImageProps } from "~/components/ui/Image"
import PopinHeader from "~/components/ui/PopinHeader"

import { showFrom } from "~/styles/utils/show-hide"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const IMAGE_SIZES = [{ breakpoint: breakpoints.lg, ratio: 3 / 15 }, { ratio: 1 / 2 }]

export type PopinWrapperProps = PropsWithChildren<
  {
    image: Nullish<ImageProps>
    header?: Nullish<string>
    containerClassName?: string
    onRemove?(): void
  } & Omit<ComponentProps<"div">, "title">
>

function PopinWrapper(
  { className, containerClassName, image, header, onRemove, children, ...rest }: PopinWrapperProps,
  ref?: Ref<HTMLDivElement>
) {
  return (
    <div ref={ref} className={clsx(className, css.Wrapper, { [css.WrapperBase]: !Boolean(className) })} {...rest}>
      {image && (
        <div className={clsx(css.imageContainer, showFrom("tablet", "block"))}>
          <FillImage className={clsx(css.image)} {...image} sizesFromBreakpoints={IMAGE_SIZES} asPlaceholder />
        </div>
      )}
      <div className={clsx(css.container)}>
        <PopinHeader className={css.header} title={header} onRemove={onRemove} />
        <div className={clsx(css.content, containerClassName)}>{children}</div>
      </div>
    </div>
  )
}

export default forwardRef<HTMLDivElement, PopinWrapperProps>(PopinWrapper)
