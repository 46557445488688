"use client"

import { useState } from "react"
import clsx from "clsx"

import Footer, { type FooterProps } from "~/components/ui/ProductCardSlider/Footer"
import Layout, { type LayoutProps } from "~/components/ui/ProductCardSlider/Layout"
import type { SliderProps } from "~/components/ui/ProductCardSlider/Layout/Slider"
import { useLinkedProducts } from "~/components/ui/ProductHeader/components/ProductDetails/components/LinkedProducts/hooks/useLinkedProducts"

import { serializeUnlikelyProductToProductCardSync } from "./_data/serializer"
import * as css from "./styles.css"

export type ProductCardSliderProps = LayoutProps & {
  locale: string
  footer: FooterProps
  dragProps?: SliderProps["dragProps"]
}

export default function ProductCardSlider({
  className,
  footer,
  isOutOfStock,
  dragProps,
  ...rest
}: ProductCardSliderProps) {
  const mainProductColorHref = rest?.link?.href?.toString()
  const [{ primaryColor, colorHref }, setPrimaryColor] = useState({
    primaryColor: footer.primaryColor,
    colorHref: mainProductColorHref,
  })

  const { data: productsData } = useLinkedProducts({
    productKey: footer.productId,
    locale: rest.locale,
    queryOptions: { enabled: mainProductColorHref !== colorHref, select: (data) => data },
  })

  const serializedProducts =
    productsData?.products?.map((product) => serializeUnlikelyProductToProductCardSync(product, rest.locale)) ?? []

  // Find the linked product with the same primary color as the current product
  // This is the product that will be displayed in the product card
  const linkedProduct = serializedProducts?.find?.((product) => product?.link?.href?.toString() === colorHref) ?? {
    isOutOfStock: null,
    footer: null,
  }
  const { isOutOfStock: linkedProductIsOutOfStock, footer: linkedProductFooter, ...linkedProductRest } = linkedProduct

  // Unless a linked product is found, we display the initial product
  // As soon as a linked product is found, we display it instead and use his props
  const initialLayoutProps = {
    isOutOfStock,
    ...rest,
  }

  const initialFooterProps = {
    ...footer,
    handle: rest?.quickBuy?.handle,
    setPrimaryColor,
    isOutOfStock,
  }

  const linkedProductLayoutProps = {
    stockAlertEnabled: Boolean(rest?.stockAlertEnabled),
    isOutOfStock: Boolean(linkedProductIsOutOfStock),
    style: rest?.style,
    layout: rest?.layout,
    widthClassName: rest?.widthClassName,
    withImageHeight: rest?.withImageHeight,
    ...linkedProductRest,
  }

  const linkedProductFooterProps = {
    ...linkedProductFooter,
    handle: rest?.quickBuy?.handle,
    setPrimaryColor,
    isOutOfStock: linkedProductIsOutOfStock,
  }

  // Decide if we should use the initial product props or the linked product props
  // We use the linked product props if:
  // - the primary color is not the same as the initial product primary color
  // - Products data is available
  const shouldUseLinkedProductProps = Boolean(
    mainProductColorHref !== colorHref && productsData?.products?.length && productsData?.products?.length > 0
  )
  const layoutProps = shouldUseLinkedProductProps ? linkedProductLayoutProps : initialLayoutProps
  const footerProps = shouldUseLinkedProductProps ? linkedProductFooterProps : initialFooterProps

  return (
    <Layout
      {...layoutProps}
      link={layoutProps?.link ?? initialLayoutProps?.link}
      slider={layoutProps?.slider ?? initialLayoutProps?.slider}
      quickBuyProps={layoutProps?.quickBuyProps ?? initialLayoutProps?.quickBuyProps}
      backInStock={layoutProps?.backInStock ?? initialLayoutProps?.backInStock}
      collections={layoutProps?.collections ?? initialLayoutProps?.collections}
      trackingData={layoutProps?.trackingData ?? initialLayoutProps?.trackingData}
      title={layoutProps?.title ?? initialLayoutProps?.title}
      price={layoutProps?.price ?? initialLayoutProps?.price}
      compareAtPrice={layoutProps?.compareAtPrice ?? initialLayoutProps?.compareAtPrice}
      vpPrice={layoutProps?.vpPrice ?? initialLayoutProps?.vpPrice}
      className={clsx(css.ProductCardSlider, className)}
      dragProps={{ ...(dragProps ? dragProps : {}) }}
    >
      <Footer
        {...footerProps}
        title={footerProps?.title ?? initialFooterProps?.title}
        productId={footerProps?.productId ?? initialFooterProps?.productId}
        isOutOfStock={footerProps?.isOutOfStock ?? initialFooterProps?.isOutOfStock}
        price={layoutProps?.price ?? initialLayoutProps?.price}
        compareAtPrice={layoutProps?.compareAtPrice ?? initialLayoutProps?.compareAtPrice}
        vpPrice={layoutProps?.vpPrice ?? initialLayoutProps?.vpPrice}
        primaryColor={primaryColor}
        colorHref={colorHref}
        className={clsx(footer.className, css.footer)}
      />
    </Layout>
  )
}
