import type { SessionEvent } from "~/lib/nosto/mutations/session/types"
import {
  UPDATE_CATEGORY_PAGE,
  UPDATE_PRODUCT_PAGE,
  UPDATE_SEARCH_PAGE,
  UPDATE_SESSION,
} from "~/lib/nosto/mutations/session/update"

export function getNostoFragmentFromEventType(type: SessionEvent["type"]) {
  switch (type) {
    case "VIEWED_PAGE":
    case "PLACED_ORDER":
      return UPDATE_SESSION
    case "VIEWED_PRODUCT":
      return UPDATE_PRODUCT_PAGE
    case "SEARCHED_FOR":
      return UPDATE_SEARCH_PAGE
    case "VIEWED_CATEGORY":
      return UPDATE_CATEGORY_PAGE
  }
}
