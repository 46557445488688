import { assignInlineVars } from "@vanilla-extract/dynamic"

import * as css from "../styles.css"

export type ProgressBarColorTheme = {
  backgroundColor?: string
  color?: string
}

export type ProgressBarProgressTheme = {
  inner: number
}
export function setProgressBarTheme(
  { inner }: ProgressBarProgressTheme,
  { backgroundColor, color }: ProgressBarColorTheme
) {
  return assignInlineVars(css.progressBarVars, {
    color: {
      progressBar: backgroundColor ?? "lightgrey",
      progressBarInner: color ?? "red",
    },
    progress: {
      inner: inner.toString(),
    },
  })
}
