"use client"

import { forwardRef, useEffect, useState, type ComponentProps, type LegacyRef } from "react"
import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { useVideo } from "~/components/abstracts/Video/VideoProvider"

import * as css from "../styles.css"

export type VideoMuteButtonProps = PropsWithClassName<
  {
    mutedChildren: React.ReactNode
    muteChildren: React.ReactNode
    ariaLabel?: {
      muted: string
      mute: string
    }
  } & ComponentProps<"button">
>

function VideoMuteButton(
  { className, mutedChildren, muteChildren, children, ariaLabel, ...rest }: VideoMuteButtonProps,
  ref?: LegacyRef<HTMLButtonElement>
) {
  const { videoRef, muted: defaultMuted } = useVideo()
  const [muted, setMuted] = useState(defaultMuted)

  const handleToggleMuted = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted
      setMuted(videoRef.current.muted)
    }
  }

  const fn = () => {
    if (videoRef.current) {
      setMuted(videoRef.current.muted)
    }
  }

  useEffect(() => {
    videoRef.current?.addEventListener("volumechange", fn)

    return () => {
      videoRef.current?.removeEventListener("volumechange", fn)
    }
  }, [])

  return (
    <button
      data-comp={"Abstracts/VideoPlayerMuteButton"}
      ref={ref}
      className={clsx(className, css.button)}
      onClick={handleToggleMuted}
      aria-pressed={muted}
      aria-label={muted ? ariaLabel?.muted ?? "muted" : ariaLabel?.mute ?? "mute"}
      {...rest}
    >
      {muted ? mutedChildren : muteChildren}
      {children}
    </button>
  )
}

export default forwardRef<HTMLButtonElement, VideoMuteButtonProps>(VideoMuteButton)
