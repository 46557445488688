const uniq = <T extends string | number>(array: T[]): T[] => {
  return Array.from(new Set(array))
}

const uniqBy = <T>(array: T[], key: keyof T): T[] => {
  return array.reduce((acc, item) => {
    if (!acc?.find((uniqueItem) => uniqueItem?.[key] === item?.[key])) {
      acc.push(item)
    }
    return acc
  }, [] as T[])
}

export { uniqBy, uniq }
