import type { UnlikelyMoney, UnlikelySfCustomer } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import type { TVPPrice } from "~/components/ui/ProductHeader/_data/serialize-vp-price"

export function processVpPrice({
  price,
  compareAtPrice,
  vpPrice,
  customer,
}: {
  price: Nullish<UnlikelyMoney>
  compareAtPrice?: Nullish<UnlikelyMoney>
  vpPrice: Nullish<TVPPrice>
  customer: UnlikelySfCustomer | undefined
}) {
  if (!vpPrice || !customer) return { processedPrice: price, processedCompareAtPrice: compareAtPrice }
  const { begin, end } = vpPrice
  const now = Date.now()

  const tooSoon = now < begin
  const tooLate = now > end

  if (tooSoon || tooLate) return { processedPrice: price, processedCompareAtPrice: compareAtPrice }
  const processedPrice = customer && vpPrice ? vpPrice.price : price
  const processedCompareAtPrice = customer && vpPrice ? vpPrice.compareAtPrice : compareAtPrice ?? null

  return { processedPrice, processedCompareAtPrice }
}
