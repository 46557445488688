import type { KeepOnlyRecordFromUnion, RemoveRecordFromUnion } from "~/@types/generics"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

export type DisplayConditions = keyof KeepOnlyRecordFromUnion<Sprinkles["display"]>
type DisplayValues = NonNullable<RemoveRecordFromUnion<Sprinkles["display"]>>

export function showFrom(breakpoint: DisplayConditions, display: DisplayValues) {
  return sprinkles({ display: { mobile: "none", [breakpoint]: display } })
}

export function hideFrom(breakpoint: DisplayConditions, display: DisplayValues) {
  return sprinkles({ display: { mobile: display, [breakpoint]: "none" } })
}
