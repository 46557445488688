import type {
  GetShopifyStorefrontClientOptions,
  ShopifySFCountryCode,
  ShopifySFLanguageCode,
  UnlikelyError,
} from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish } from "~/@types/generics"
import { getRevalidateTags, type RevalidateTag } from "~/lib/constants/revalidate-tags"
import { getCountry, getLang } from "~/lib/i18n/utils/get-i18n"
import { getStorePublicCredentials } from "~/lib/shopify/client/public"
import { processedRevalidate } from "~/lib/vercel/constants"
import { uniq } from "~/utils/uniq"

// Replace with commerce-connector's type when ClientOptions will be exported
export interface RequestCacheOptions {
  cache?: RequestCache
  next?: Omit<NextFetchRequestConfig, "tags">
}

// Replace with commerce-connector's type when ClientOptions will be exported
export interface ClientOptions {
  requestCacheOptions?: RequestCacheOptions
  fetchClient?: typeof fetch
}

export function sfFetchWrapper<
  TCallback extends (
    shop: GetShopifyStorefrontClientOptions,
    input: TInput,
    options: TOptions
  ) => Promise<{ errors: UnlikelyError[] }>,
  TInput = Parameters<TCallback>[1],
  TOptions extends ClientOptions = ClientOptions
>(callback: TCallback) {
  return (...tags: RevalidateTag[]) => {
    return async (
      {
        locale,
        withLocale = true,
        ...input
      }: TInput & ({ locale: Nullish<string>; withLocale?: true } | { locale?: never; withLocale: false }),
      options?: TOptions
    ) => {
      const location = withLocale ? getSfLocation(locale) : {}

      const shop = getStorePublicCredentials()

      const response = await callback(shop, { ...location, ...input } as TInput, getSfOptions(options, ...tags))

      if (response.errors.length > 0) {
        throw Error(response.errors[0]?.message)
      }

      return response as InferReturn<TCallback>
    }
  }
}

function getSfOptions<TOptions extends ClientOptions = ClientOptions>(options?: TOptions, ...tags: RevalidateTag[]) {
  return {
    ...(options ?? ({} as TOptions)),
    fetchClient: fetch,

    requestCacheOptions: {
      cache: options?.requestCacheOptions?.cache,
      next: {
        ...(options?.requestCacheOptions?.next ?? {}),
        revalidate: processedRevalidate,
        tags: uniq([...getRevalidateTags("shopify"), ...tags]),
      },
    },
  } satisfies ClientOptions
}

function getSfLocation(locale: Nullish<string>) {
  const language = getLang(locale).toUpperCase() as ShopifySFLanguageCode
  const country = getCountry(locale).toUpperCase() as ShopifySFCountryCode

  return {
    language,
    country,
  }
}
