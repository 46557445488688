import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { InferReturn, Nullish } from "~/@types/generics"
import { productMetafields, type COLORS_VALUE } from "~/lib/shopify/constants"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { getProductColorByValue } from "~/lib/shopify/utils/get-product-color-by-value"
import serializeLink from "~/components/ui/Link/_data/serializer"
import { serializeVariants } from "~/components/ui/ProductHeader/_data/serialize-variant"
import hexToHSL from "~/utils/hex-to-hsl"
import { metafieldsToObject } from "~/utils/metafields-to-object"

export type TProductColor = InferReturn<typeof serializeUnlikelyProductColor>

export function serializeUnlikelyProductColor(
  { product, active = false }: { product: UnlikelyProduct; active?: boolean },
  locale: Nullish<string>
) {
  const metafields =
    product.metafields && metafieldsToObject(product.metafields, getLocalizedMetafields(productMetafields, locale))
  const variants = serializeVariants(product?.variants, locale)
  const primaryColor = metafields?.primaryColor as keyof typeof COLORS_VALUE
  const colorName = variants[0]?.color

  if (!colorName || !primaryColor) return null

  const link = product.id && product.handle ? serializeLink({ id: product.id, handle: product.handle }, locale) : null

  const hexa = getProductColorByValue(primaryColor)

  return {
    link,
    active,
    value: colorName,
    hexa,
  }
}

export type SerializedLinkedProducts = ReturnType<typeof serializeLinkedProducts>
export function serializeLinkedProducts(
  currentProduct: UnlikelyProduct,
  linkedProducts: UnlikelyProduct[],
  locale: Nullish<string>
) {
  const linkedProductsColor = linkedProducts.map((product) =>
    serializeUnlikelyProductColor({ product, active: product.handle === currentProduct.handle }, locale)
  )

  return sortLinkedProducts(linkedProductsColor)
}

export function sortLinkedProducts(linkedProductsColors: TProductColor[]) {
  return linkedProductsColors
    .filter(Boolean)
    .sort((a, b) => ((hexToHSL(a.hexa)?.l ?? 0) > (hexToHSL(b.hexa)?.l ?? 0) ? 1 : -1))
}
