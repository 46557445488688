"use client"

import { useAtom } from "jotai"

import { collectionTreeAtom } from "~/managers/CollectionsTreeManager/store"

import "~/managers/InternalRedirectsManager/ClientInternalRedirectsManager"

export function useCollectionTree() {
  return useAtom(collectionTreeAtom)
}
