import type { Nullish } from "~/@types/generics"
import { getLang } from "~/lib/i18n/utils/get-i18n"
import type { Metafields } from "~/lib/shopify/constants"

type ReturnType<M extends Metafields> = { [key in Partial<keyof (M["fr"] & M["en"] & M["universal"])>]: string }

export function getLocalizedMetafields<const M extends Metafields>(
  metafields: M,
  locale: Nullish<string>
): ReturnType<M> {
  return { ...metafields[getLang(locale)], ...metafields["universal"] } as ReturnType<M>
}
