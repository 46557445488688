import { useState, type Dispatch, type RefObject, type SetStateAction } from "react"
import { useIsMounted, useWindowSize } from "usehooks-ts"

import { useIsomorphicLayoutEffect } from "@unlikelystudio/react-hooks"

import { canUseDom } from "~/utils/can-use-dom"

type Rect = {
  width: number
  height: number
}

type InitObserverCallback = {
  ref: RefObject<HTMLElement>
  setRect: Dispatch<SetStateAction<DOMRect | Rect>>
  method?: "borderBoxSize" | "getBoundingClientRect"
}

let observer: ResizeObserver | null = null
let hasBeenInitializedAtLeastOneTime = false
const observedDOMElement = [] as HTMLElement[]

function initObserver({ ref, method, setRect }: InitObserverCallback) {
  if (!hasBeenInitializedAtLeastOneTime) {
    hasBeenInitializedAtLeastOneTime = true
    observer = canUseDom ? new ResizeObserver(updateRectState) : null
  }

  ref.current && observedDOMElement.push(ref.current)

  function updateRectState(entries: ResizeObserverEntry[]) {
    entries.forEach((entry) => {
      const isObserved = observedDOMElement.find((node) => node === entry.target)

      if (isObserved) {
        const borderBoxHeight = entry?.borderBoxSize?.[0]?.blockSize
        const borderBoxWidth = entry?.borderBoxSize?.[0]?.inlineSize

        let rect: DOMRect = {} as DOMRect
        if (method === "getBoundingClientRect" || !borderBoxHeight || !borderBoxWidth) {
          rect = entry.target.getBoundingClientRect()
        }
        setRect({
          width: borderBoxWidth ?? rect.width,
          height: borderBoxHeight ?? rect.height,
          ...(method === "getBoundingClientRect"
            ? {
                bottom: rect.bottom,
                top: rect.top,
                left: rect.left,
                right: rect.right,
                x: rect.x,
                y: rect.y,
              }
            : {}),
        })
      }
    })
  }
}

export function useMeasureObserverCustom(ref: RefObject<HTMLElement>, method?: "borderBoxSize", deps?: unknown[]): Rect

export function useMeasureObserverCustom(
  ref: RefObject<HTMLElement>,
  method?: "getBoundingClientRect",
  deps?: unknown[]
): DOMRect

export function useMeasureObserverCustom(
  ref: RefObject<HTMLElement>,
  method?: "borderBoxSize" | "getBoundingClientRect",
  deps?: unknown[]
): DOMRect | Rect {
  const isMounted = useIsMounted()()
  const [rect, setRect] = useState<DOMRect | Rect>(
    method === "borderBoxSize"
      ? {
          width: 0,
          height: 0,
        }
      : {
          width: 0,
          height: 0,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          x: 0,
          y: 0,
        }
  )

  useIsomorphicLayoutEffect(() => {
    if (ref?.current && isMounted) {
      initObserver({ ref, method, setRect })
      if (observer) {
        observer.observe(ref?.current, { box: "border-box" })
      }
    }

    return () => {
      if (ref?.current && observer) {
        observer.unobserve(ref?.current)
      }
    }
  }, [ref?.current, isMounted])

  const windowSize = useWindowSize()

  useIsomorphicLayoutEffect(() => {
    if (ref?.current && method === "getBoundingClientRect") {
      const newRect = ref.current.getBoundingClientRect()
      setRect({
        width: newRect.width,
        height: newRect.height,
        bottom: newRect.bottom,
        top: newRect.top,
        left: newRect.left,
        right: newRect.right,
        x: newRect.x,
        y: newRect.y,
      })
    }
  }, [windowSize, ...(deps ?? [])])

  return rect
}
