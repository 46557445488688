import VideoMuteButton from "~/components/abstracts/Video/VideoMuteButton"
import VideoPlayer from "~/components/abstracts/Video/VideoPlayer"
import VideoPlayPauseButton from "~/components/abstracts/Video/VideoPlayPauseButton"
import VideoProgressBar from "~/components/abstracts/Video/VideoProgressBar"
import VideoProvider, { type VideoProviderProps } from "~/components/abstracts/Video/VideoProvider"
import VideoVolumeBar from "~/components/abstracts/Video/VideoVolumeBar"

const Root = VideoProvider
const Player = VideoPlayer
const ProgressBar = VideoProgressBar
const VolumeBar = VideoVolumeBar
const PlayPauseButton = VideoPlayPauseButton
const MuteButton = VideoMuteButton

const Default = {
  Root,
  Player,
  ProgressBar,
  VolumeBar,
  PlayPauseButton,
  MuteButton,
}

export default Default

export type VideoRootProps = Omit<VideoProviderProps, "children">
export type { VideoMuteButtonProps } from "~/components/abstracts/Video/VideoMuteButton"
export type { VideoPlayPauseButtonProps } from "~/components/abstracts/Video/VideoPlayPauseButton"
export type { VideoProgressBarProps } from "~/components/abstracts/Video/VideoProgressBar"
export type { VideoVolumeBarProps } from "~/components/abstracts/Video/VideoVolumeBar"
