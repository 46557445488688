import type { UnlikelyMetafields } from "@unlikelystudio/commerce-connector"

import { objectKeys } from "~/utils/object-keys"

export function metafieldsToObject<T extends UnlikelyMetafields, K extends Record<string, string>>(
  metafields: T,
  keys: K
): Record<keyof K, T[keyof T]["value"]> | null {
  if (!metafields) return null
  const v = Object.entries(metafields).reduce((acc, [key, value]) => {
    if (!key) return acc
    const newKey = objectKeys(keys).find((val) => keys[val] === key)
    if (!newKey) return acc
    acc = { ...acc, [newKey]: value.value }
    return acc
  }, {} as Record<keyof K, T[keyof T]["value"]>)
  return v
}
