import { useMutation, type UseMutationOptions } from "@tanstack/react-query"
import { env } from "~/env"
import type { ErrorOption } from "react-hook-form"

import { BACK_IN_STOCK_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { useAlerts } from "~/hooks/useAlerts"
import useOnMutationError from "~/hooks/useOnMutationError"
import type { BackInStockMutationProps } from "~/components/ui/BackInStockPopin/Wrapper"

export default function useBackInStockMutation(
  mutationOptions?: UseMutationOptions<unknown, unknown, BackInStockMutationProps>,
  setError?: (name?: string, error?: ErrorOption) => void
) {
  const onMutationError = useOnMutationError(setError)
  // browser's UTC offset in seconds
  const utcOffset = String(new Date().getTimezoneOffset() * 60)
  const shopUrl = new URL(env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN)
  const shop = shopUrl.hostname
  const quantityRequired = String(1)
  const triggerAlert = useAlerts()

  return useMutation<unknown & { success: boolean; data: any }, unknown, BackInStockMutationProps>(
    async ({ email, acceptsMarketing = true, variantId, productId }) => {
      return await requestNextApiEndpoint(BACK_IN_STOCK_PARAMS, {
        payload: {
          email,
          shop,
          utcOffset,
          quantityRequired,
          productId,
          variantId,
          acceptsMarketing,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        if (data?.success === false) {
          if (
            data?.data?.status === "Error" &&
            data?.data?.errors?.base?.[0] === "You have already registered for a notification for that item."
          ) {
            triggerAlert("BACK_IN_STOCK_ALREADY_SUBSCRIBED")
          } else {
            onMutationError(null, rest, mutationOptions?.onError)
          }
        }
        mutationOptions?.onSuccess && mutationOptions.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    }
  )
}
