import { globalInputPreset } from "~/components/abstracts/Input/utils/global-input-preset"
import { EMAIL_REGEX } from "~/components/abstracts/Input/utils/regex"
import { formKeys } from "~/providers/I18nProvider/constants"

export const inputPresets = {
  [globalInputPreset.email]: {
    type: "text",
    required: true,
    autoComplete: "email",
    name: "email",
    placeholder: formKeys.email,
    validations: [{ regex: EMAIL_REGEX, key: "email" }],
  },
}

export type InputPresets = keyof typeof inputPresets
