import { useQuery } from "@tanstack/react-query"

import type { InferReturn } from "~/@types/generics"
import { getSfProducts } from "~/lib/shopify/queries/get-sf-products"

type TData = InferReturn<typeof getSfProducts>

export function useSfProductsQuery<TError = unknown>(
  params: Parameters<typeof getSfProducts>[0],
  options?: Parameters<typeof useQuery<TData, TError, TData, [Parameters<typeof getSfProducts>[0]]>>[2]
) {
  return useQuery<TData, TError, TData, [Parameters<typeof getSfProducts>[0]]>(
    [params],
    async () => await getSfProducts(params),
    {
      // Set StaleTime to revalidate data after 15 minutes
      staleTime: 15 * 60 * 1000,
      ...options,
    }
  )
}
