import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { getDefaultVariant } from "~/lib/shopify/utils/get-default-variants"
import { serializeVariants } from "~/components/ui/ProductHeader/_data/serialize-variant"

export type ProductVariant = UnlikelyProduct["variants"][0]

export default function serializeVariantsSelector(product: UnlikelyProduct, locale: Nullish<string>) {
  const variants = serializeVariants(product?.variants, locale)
  const defaultVariant = getDefaultVariant(variants, product)

  const options = variants.map((variant) => variant?.title?.split("/")?.[0] ?? null)

  return {
    options,
    variants,
    defaultVariant,
  }
}
