import type { UnlikelyMoney } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"

export type VPPayload = {
  [key: string]: {
    price: number
    compareAtPrice: number
    begin: number
    end: number
  }
}

export type TVPPrice = {
  price: UnlikelyMoney
  compareAtPrice: UnlikelyMoney
  begin: number
  end: number
}
export function serializeVpPrices(value: string, market: Nullish<string>, currencyCode: string): TVPPrice | null {
  if (!market) return null
  try {
    const parsedValue = JSON.parse(value) as VPPayload

    const marketParsedValues = parsedValue?.[market]

    if (!marketParsedValues) return null
    const { price, compareAtPrice, end, begin } = marketParsedValues

    if (!end || !begin || !price || !compareAtPrice || end < begin) return null
    if (typeof end !== "number" || typeof begin !== "number") return null
    const vpPrice = {
      price: { amount: marketParsedValues.price, currencyCode },
      compareAtPrice: {
        amount:
          marketParsedValues.compareAtPrice !== marketParsedValues.price ? marketParsedValues.compareAtPrice : null,
        currencyCode,
      },
      begin,
      end,
    } satisfies TVPPrice

    return vpPrice
  } catch {
    return null
  }
}
