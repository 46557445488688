"use client"

import {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  type ComponentProps,
  type Ref,
  type RefObject,
} from "react"
import { useEventListener, useLockedBody, useOnClickOutside } from "usehooks-ts"

export type ElementProps = ComponentProps<"div"> & {
  id?: string
  removeCurrent(): void
  scrollDisabled?: boolean
  clickOutsideRef?: RefObject<HTMLElement>
  onRemove?(e?: MouseEvent): void
  customDefaultRemove?(e?: MouseEvent): void
}

/**
 * Element Component
 * @param {string} [props.className] - className for the element
 * @param {boolean} [props.scrollDisabled] - @default {true} - disabled scroll on element activation
 * @param {RefObject<HTMLElement>} [props.clickOutsideRef] - ref of children div for enable close on click outside
 * @param {void} [props.onClickOutside] - method to trigger when click outside
 * @example
 * ```tsx
 * import { Element } from '...'
 *
 * function Demo() {
 *  const containerRef = useRef<HTMLDivElement>(null)
 *
 *  return (
 *    <Element
 *      // if you want to custom element
 *      className={css.Element}
 *
 *      // if you want to not block scroll on element opened
 *      scrollDisabled={false}
 *
 *      // if you want to close element on click overlay
 *      clickOutsideRef={containerRef}
 *      >
 *      <div ref={containerRef}>
 *        // Our element container
 *      </div>
 *    </Element>
 *  )
 * }
 *
 * ```
 */
function Element(
  {
    className,
    children,
    removeCurrent,
    scrollDisabled = true,
    clickOutsideRef,
    onRemove,
    customDefaultRemove,
    ...props
  }: ElementProps,
  ref?: Ref<HTMLDivElement>
) {
  const elementRef = createRef<HTMLDivElement>()

  useImperativeHandle(ref, () => elementRef.current as HTMLDivElement, [elementRef])

  const handleOnRemove = (e?: MouseEvent) => {
    const remove = () => (customDefaultRemove ? customDefaultRemove(e) : removeCurrent())
    remove()
    onRemove?.(e)
  }

  useLockedBody(scrollDisabled, "__next")
  useOnClickOutside(clickOutsideRef ?? elementRef, clickOutsideRef ? (e) => handleOnRemove(e) : () => {}, "mousedown")

  useEventListener(
    "keydown",
    (e) => {
      if (e.code === "Escape") {
        handleOnRemove()
      }
    },
    elementRef
  )

  useEffect(() => {
    elementRef.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={elementRef} className={className} tabIndex={0} {...props}>
      {children}
    </div>
  )
}

export default forwardRef<HTMLDivElement, ElementProps>(Element)
