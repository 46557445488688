import { cloneElement } from "react"
import { atom } from "jotai"
import { atomWithReset } from "jotai/utils"

export type StackElement = {
  id: string
  element: JSX.Element
}

const defaultCallbacks = {
  onAdd: (all: StackElement[], element: StackElement) => [element, ...all],
  onRemoveCurrent: ([, ...all]: StackElement[]) => all ?? [],
  onRemoveElement: (all: StackElement[], id: string) => all.filter((element) => element.id !== id),
  getCurrent: (all: StackElement[]) => all[0] ?? null,
}

export interface CreateStoreCallbacks {
  onAdd?(all: StackElement[], element: StackElement): StackElement[]
  onRemoveCurrent?(all: StackElement[]): StackElement[]
  onRemoveElement?(all: StackElement[], id: string): StackElement[]
  getCurrent?(all: StackElement[]): StackElement | null
}

function createStore(callbacks?: CreateStoreCallbacks) {
  const { onAdd, onRemoveCurrent, onRemoveElement, getCurrent } = {
    ...defaultCallbacks,
    ...callbacks,
  }

  const allAtom = atomWithReset<StackElement[]>([])

  const currentAtom = atom((get) => getCurrent(get(allAtom)))

  const addAtom = atom(null, (get, set, element: StackElement) => {
    set(allAtom, onAdd(get(allAtom), reshapeStackElement(element)))
  })

  const removeCurrentAtom = atom(null, (get, set) => set(allAtom, onRemoveCurrent(get(allAtom))))

  const removeElementAtom = atom(null, (get, set, id: string) => set(allAtom, onRemoveElement(get(allAtom), id)))

  return {
    allAtom,
    addAtom,
    removeCurrentAtom,
    removeElementAtom,
    currentAtom,
  }
}

export default createStore

function reshapeStackElement(data: StackElement) {
  const id = `${+new Date()}_${data.id}`

  return {
    id,
    element: cloneElement(data.element, { ...data.element.props, id, key: id }),
  }
}
