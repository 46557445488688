import type { AbstractInputProps } from "~/components/abstracts/Input"
import { inputPresets, type InputPresets } from "~/components/abstracts/Input/utils/input-preset"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

export default function useInputPreset<T extends AbstractInputProps>(
  key: InputPresets | undefined,
  args: T
): Partial<T> {
  const t = useTranslate()
  const hasKey = key

  if ((hasKey && !inputPresets[key]) || !hasKey) {
    return args
  }

  const currentPreset = inputPresets?.[key]

  const validate =
    "validations" in currentPreset && currentPreset?.validations?.length
      ? currentPreset.validations.reduce<Record<string, (val: string) => boolean>>((acc, { key, regex }) => {
          acc[key] = (val: string) => {
            return regex.test(val)
          }
          return acc
        }, {})
      : {}

  return {
    ...currentPreset,
    validate,
    placeholder: args?.placeholder || t(currentPreset?.placeholder),
    ...args,
  }
}
