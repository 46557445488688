import type { Nullish } from "~/@types/generics"
import { getSfProductByHandle } from "~/lib/shopify/queries/get-sf-product-by-handle"
import { serializeSfQuickBuy } from "~/components/ui/ProductCardSlider/Layout/QuickBuy/_data/serializer"

export async function getProductQuickbuyVariants(handle: Nullish<string>, locale: string) {
  if (!handle) return []
  const { productByHandle } = await getSfProductByHandle({ handle, locale })
  const quickbuyButtons = (await serializeSfQuickBuy(productByHandle, locale)) ?? []

  return quickbuyButtons
}
