"use client"

import { createContext, useContext, useRef, type ComponentProps, type MutableRefObject, type ReactNode } from "react"

function VideoProvider({ children, ...videoProps }: VideoProviderProps) {
  const videoRef = useRef<HTMLVideoElement>(null)

  const videoContextValue = {
    videoRef,
    ...videoProps,
  }

  return <VideoContext.Provider value={videoContextValue}>{children}</VideoContext.Provider>
}

export default VideoProvider
export { useVideo, type Video }

interface Video extends Omit<VideoProviderProps, "children"> {
  videoRef: MutableRefObject<HTMLVideoElement | null>
}

export interface VideoProviderProps extends ComponentProps<"video"> {
  sources: ComponentProps<"source">[]
  children: ReactNode
}

const VideoContext = createContext<Video>({} as Video)

function useVideo() {
  return useContext(VideoContext)
}
