"use client"

import {
  forwardRef,
  type ComponentProps,
  type DetailedHTMLProps,
  type ForwardRefExoticComponent,
  type HTMLAttributes,
  type RefAttributes,
} from "react"
import { useAtomValue, useSetAtom } from "jotai"
import { useResetAtom } from "jotai/utils"

import DefaultElement from "./components/Element"
import createStore, { type CreateStoreCallbacks, type StackElement } from "./store"

type StackType = "unique" | "multiple"

type UseManagerReturnType = {
  unique: {
    items: JSX.Element[]
    current: JSX.Element | null
    add: (update: JSX.Element) => void
    removeCurrent: (update?: unknown) => void
    reset: () => void
  }
  multiple: {
    items: StackElement[]
    add: (update: StackElement) => void
    reset: () => void
    getElement: (id: string) => StackElement | null
    removeElement: (id: string) => void
  }
}

type Manager = {
  unique: () => JSX.Element | null
  multiple: ForwardRefExoticComponent<
    Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & RefAttributes<HTMLDivElement>
  >
}

type ReturnType<T extends StackType> = [
  Manager[T],
  ForwardRefExoticComponent<Omit<ElementProps, "ref"> & RefAttributes<HTMLDivElement>>,
  () => UseManagerReturnType[T]
]

/**
 * Create Stack Component Manager
 * The `createStackComponentManager` function is a utility function that creates a stack component
 * manager for managing multiple or unique elements in a React application.
 * @returns The function `createStackComponentManager` returns an array containing three elements:
 * @example
 * ```tsx
 * import createStackComponentManager from '...'
 *
 * const [PanelManager, AbstractPanel, usePanel] = createStackComponentManager()
 *
 * export function Panel({ className, ...props }: ElementProps) {
 *  return <AbstractPanel className={cx(css.Panel, className)} {...props} />
 * }
 *
 * export { PanelManager, usePanel }
 * ```
 */
function createStackComponentManager(type: "multiple", callbacks?: CreateStoreCallbacks): ReturnType<"multiple">
function createStackComponentManager(type?: "unique", callbacks?: CreateStoreCallbacks): ReturnType<"unique">
function createStackComponentManager(
  type: StackType = "unique",
  callbacks?: CreateStoreCallbacks
): ReturnType<StackType> {
  // Generate all Atoms instance
  const { allAtom, currentAtom, addAtom, removeCurrentAtom, removeElementAtom } = createStore(callbacks)

  // Create hooks to manage everything
  function useManager(): UseManagerReturnType[typeof type] {
    const items = useAtomValue(allAtom)
    const current = useAtomValue(currentAtom)
    const add = useSetAtom(addAtom)
    const removeCurrent = useSetAtom(removeCurrentAtom)
    const reset = useResetAtom(allAtom)
    const getElement = (id: string) => items.find((item) => item.id === id) ?? null
    const removeElement = useSetAtom(removeElementAtom)

    if (type === "multiple")
      return {
        items,
        add,
        reset,
        getElement,
        removeElement,
      }

    const addUnique = (element: JSX.Element) => {
      add({ id: "", element })
    }

    return {
      items: items.map((item) => item.element).filter(Boolean),
      current: current?.element ?? null,
      add: addUnique,
      removeCurrent,
      reset,
    }
  }

  // Create componant that will manager current element
  const Manager = {
    unique: function Unique() {
      const { current } = useManager() as UseManagerReturnType["unique"]

      return current
    },
    multiple: forwardRef<HTMLDivElement, ComponentProps<"div">>(function (props, ref?) {
      const { items } = useManager() as UseManagerReturnType["multiple"]

      if (!items?.length) return null

      return (
        <div ref={ref} {...props}>
          {items.map((item) => item.element)}
        </div>
      )
    }),
  }[type]

  // Create container compoment
  const Element = {
    unique: forwardRef<HTMLDivElement, ElementProps>(function (props, ref?) {
      const { removeCurrent } = useManager() as UseManagerReturnType["unique"]

      return <DefaultElement ref={ref} removeCurrent={removeCurrent} {...props} />
    }),
    multiple: forwardRef<HTMLDivElement, ElementProps>(function (props, ref?) {
      const { removeElement } = useManager() as UseManagerReturnType["multiple"]

      return <DefaultElement ref={ref} removeCurrent={() => props?.id && removeElement(props.id)} {...props} />
    }),
  }[type]

  return [Manager, Element, useManager]
}

export type ElementProps = Omit<ComponentProps<typeof DefaultElement>, "removeCurrent">
export default createStackComponentManager
