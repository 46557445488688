import { usePathname } from "next/navigation"

import useCurrency from "~/hooks/useCurrency"
import { useGetCart } from "~/hooks/useGetCart"
import type { PageName } from "~/providers/GTMTrackingProvider/constants"

export function useGlobalTrackingProps(pageTitle?: PageName) {
  const { cartPayload } = useGetCart()
  const currency = useCurrency()
  const pathname = usePathname()

  return {
    currency: currency.toUpperCase(),
    pagePath: pathname,
    pageTitle,
    content_type: "Product",
    value: cartPayload?.cost.totalAmount.amount ?? 0,
  }
}
