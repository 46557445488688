import { env } from "~/env"
import StoryblokClient from "storyblok-js-client"

import { getRevalidateTags } from "~/lib/constants"
import { processedRevalidate } from "~/lib/vercel/constants"

export const Storyblok = new StoryblokClient({
  accessToken: env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  cache: {
    type: "none",
  },
  fetch: (url, init) =>
    fetch(url, {
      ...init,
      next: { revalidate: processedRevalidate, tags: getRevalidateTags("storyblok") },
    }),
})

export default Storyblok
