import { z } from "zod"

import { sbPlugin } from "~/lib/storyblok/schemas/helpers"

export function sbPluginIntegrationShopifyCollectionSchema() {
  return sbPlugin("integration-shopify-collection")
    .extend({
      data: z
        .object({
          id: z.string(),
          handle: z.string(),
        })
        .nullable(),
    })
    .transform(({ data }) => data)
}

export type SbPluginIntegrationShopifyCollection = z.infer<
  ReturnType<typeof sbPluginIntegrationShopifyCollectionSchema>
>
