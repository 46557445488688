import { useEffect, useRef } from "react"
import { env } from "~/env"
import { useAtomValue } from "jotai"

import { useGetCart as useAbstractGetCart, useUpdateCartAttributes } from "@unlikelystudio/react-ecommerce-hooks"

import { CHECKOUT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import { nostoSessionAtom } from "~/components/globals/NostoSessionHandler"

export function useGetCart() {
  const { data: cartPayload, isFetched: cartQueryIsComplete, isLoading: isAbstractLoading } = useAbstractGetCart()

  const nostoSessionId = useAtomValue(nostoSessionAtom)

  const cartItemsNumber = cartPayload?.lines?.reduce((acc, item) => acc + (item?.quantity ?? 0), 0) ?? 0
  const isMutatingCart = useRef(false)

  const { mutate: updateCartCustomAttribute } = useUpdateCartAttributes({
    onSuccess: () => {
      isMutatingCart.current = false
    },
  })

  const getCustomAttribute = (id: string) => {
    return cartPayload?.attributes?.find((item) => item.key === id)
  }

  useEffect(() => {
    /* This is a guard clause to prevent the hook from running if the checkout is already being
    mutated, if the checkout doesn't have a checkoutUrl or if the checkout is still loading. */
    if (isMutatingCart.current === true || !cartPayload?.checkoutUrl || isAbstractLoading) return

    // Add a checkout env attribute at the root of the checkout to get env param in GTM head in Shopify checkout
    const checkoutEnv = cartPayload?.attributes?.find((item) => item?.key === CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_ENV)

    /* Checking if the checkout has a custom attribute called checkoutLang and if it does not, it is
   adding it. */
    if (
      !getCustomAttribute(CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_DOMAIN_REFERRAL) ||
      !getCustomAttribute(CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_NOSTO_SESSION_ID) ||
      checkoutEnv?.value !== env.NEXT_PUBLIC_ENV
    ) {
      isMutatingCart.current = true

      updateCartCustomAttribute({
        cartId: cartPayload.id,
        attributes: [
          /* Adding a custom attribute checkoutDomainReferral to the checkout. */
          {
            key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_DOMAIN_REFERRAL,
            value: window.location.origin,
          },
          {
            key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_ENV,
            value: env.NEXT_PUBLIC_ENV,
          },
          nostoSessionId
            ? {
                key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_NOSTO_SESSION_ID,
                value: nostoSessionId.toString(),
              }
            : undefined,
        ].filter(Boolean),
      })
    }
  }, [cartPayload])

  return { cartPayload, cartItemsNumber, cartQueryIsComplete }
}
