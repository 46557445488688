import dynamic from "next/dynamic"

import { useAddCartLines } from "@unlikelystudio/react-ecommerce-hooks"

import { getProductCustomAttributesValue } from "~/lib/shopify/constants"
import type { AddtoCartCtaProps } from "~/components/ui/AddToCartCta"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import { useGlobalTrackingProps } from "~/providers/GTMTrackingProvider/hooks/use-get-tracking-globals"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import { usePanel } from "~/managers/PanelManager"

const CartPanel = dynamic(() => import("~/components/ui/CartPanel"))

export function useAddToCart(
  activeVariants: AddtoCartCtaProps["activeVariants"],
  onSuccess?: AddtoCartCtaProps["onSuccess"]
) {
  const variants = (Array.isArray(activeVariants) ? activeVariants : [activeVariants]).filter(Boolean)
  const { add } = usePanel()

  const { sendEvent } = useTracking()
  const globalTrackingProps = useGlobalTrackingProps()

  const { mutate, isLoading } = useAddCartLines({
    onSuccess: (data) => {
      onSuccess?.()
      add(<CartPanel />)
      const lines =
        variants
          ?.map((variant) => data?.cart?.lines.find((line) => line.merchandise?.id === variant?.id))
          .flatMap((item) => (item?.id ? [item] : [])) ?? []

      if (lines?.length === 0) return

      try {
        sendEvent("add_to_cart", {
          ecommerce: {
            ...globalTrackingProps,
            value: data.cart?.cost?.totalAmount?.amount ?? 0,
            items: lines.map((line) => {
              const trackingData = getProductCustomAttributesValue(line.attributes, "TRACKING_DATA")?.value ?? "{}"

              const parsedTrackingData = JSON.parse(trackingData) as CartLineItemPayload

              return {
                ...parsedTrackingData,
                quantity: 1,
              }
            }),
          },
        })
      } catch {}
    },
  })

  return { mutate, isLoading }
}
