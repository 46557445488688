import type { UnlikelySfCustomer } from "@unlikelystudio/commerce-connector"

import type { SessionCustomer } from "~/lib/nosto/mutations/session/types"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"

export function getCustomerInput(customer?: UnlikelySfCustomer): SessionCustomer {
  if (!customer) return {}

  const { id, email, firstName, lastName, acceptsMarketing } = customer ?? {}
  const referenceId = getReferenceIdFromGID(id)?.toString()

  return {
    ...(referenceId && { customerId: referenceId }),
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(email && { customerReference: btoa(email), email }),
    ...(customer && { doNotTrack: true, marketingPermission: acceptsMarketing ?? false }),
  }
}
