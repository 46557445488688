import { forwardRef, useMemo, useState, type ChangeEvent, type Ref } from "react"
import type { z } from "zod"

import type { Nullish } from "~/@types/generics"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import { useAlerts } from "~/hooks/useAlerts"
import useBackInStockMutation from "~/hooks/useBackInStockMutation"
import { useFormWithMutation } from "~/hooks/useFormWithMutation"
import type { BackInStockProps, BackInStockVariant } from "~/components/ui/BackInStockPopin"
import type { backInStockMutationSchema } from "~/components/ui/BackInStockPopin/_data/schema"
import Checkbox from "~/components/ui/Checkbox"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import Input from "~/components/ui/Input"
import PopinWrapper from "~/components/ui/PopinWrapper"
import RichText from "~/components/ui/RichText"
import Spinner from "~/components/ui/Spinner"
import SquareCta from "~/components/ui/SquareCta"
import { globalCheckboxPreset } from "~/components/abstracts/Checkbox/utils/global-checkbox-preset"
import { globalInputPreset } from "~/components/abstracts/Input/utils/global-input-preset"
import LayoutForm from "~/components/abstracts/LayoutForm"
import Select from "~/components/abstracts/Select"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePopin } from "~/managers/PopinManager"

import * as css from "./styles.css"

export type TBackInStockWrapper = {
  title: Nullish<string>
  paragraph: Nullish<SbRichtext>
  image: Nullish<TImage>
  privacyPolicyLabel: Nullish<SbRichtext>
  successTitle: Nullish<string>
}
export type BackInStockWrapperProps = TBackInStockWrapper &
  Pick<BackInStockProps, "productId" | "variants" | "defaultVariant">

export type BackInStockMutationProps = z.infer<typeof backInStockMutationSchema>

function Wrapper(
  {
    productId,
    variants,
    defaultVariant,
    title,
    image,
    paragraph,
    successTitle,
    privacyPolicyLabel,
  }: BackInStockWrapperProps,
  ref?: Ref<HTMLDivElement>
) {
  const triggerAlert = useAlerts()
  const t = useTranslate()
  const { removeCurrent } = usePopin()
  const [subscribedSuccess, setSubscribedSuccess] = useState<boolean>(false)
  const { mutate, isLoading } = useBackInStockMutation({
    onSuccess: (data) => {
      if (data && typeof data === "object" && "success" in data && data?.success) setSubscribedSuccess(true)
    },
    onError: () => {
      triggerAlert()
    },
  })

  const [currentVariant, setCurrentVariant] = useState(
    variants.find((variant) => variant.id === defaultVariant) ?? variants?.[0]
  )

  const handleOnChangeVariant = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as NonNullable<BackInStockVariant["id"]>
    setCurrentVariant(variants.find((variant) => variant.id === value))
  }

  const variantOptions = useMemo(
    () =>
      variants
        ?.map((variant) => {
          if (!variant.id || !variant.title) return null

          return {
            value: variant.id,
            label: variant.title,
            selected: variant.id === currentVariant?.id,
          }
        })
        .filter(Boolean),
    [variants, currentVariant?.id]
  )

  const handleMutation = (data: BackInStockMutationProps) => {
    const productRefId = getReferenceIdFromGID(productId)
    const variantId = getReferenceIdFromGID(currentVariant?.id)
    if (productId && variantId) {
      mutate({ ...data, productId: String(productRefId), variantId: String(variantId) })
    }
  }

  const { form, onSubmit } = useFormWithMutation<BackInStockMutationProps>(handleMutation, {}, "newsletter")

  return (
    <PopinWrapper image={image} header={t("back_in_stock_popin_title")} ref={ref}>
      {subscribedSuccess ? (
        <div className={css.block}>
          <div className={css.successTitle}>{successTitle}</div>
          <SquareCta fill theme="backgroundBlack" onClick={() => removeCurrent()} aria-label={t("aria_submit")}>
            {t("cta_close")}
          </SquareCta>
        </div>
      ) : (
        <>
          <div className={css.block}>
            {title && <h3 className={css.title}>{title}</h3>}
            {paragraph && <RichText className={css.paragraph} render={paragraph} />}
          </div>
          <LayoutForm className={css.form} form={form} onSubmit={onSubmit}>
            {variantOptions && <Select name="variantId" options={variantOptions} onChange={handleOnChangeVariant} />}
            <Input
              sprinklesCss={{ paddingLeft: 0 }}
              className={css.InputContainer}
              inputWrapperClassName={css.inputWithButtonWrapper}
              errorClassname={css.newsletterInputError}
              inputClassName={css.mailInput}
              preset={globalInputPreset.email}
            />

            <Checkbox
              errorClassname={css.newsletterInputError}
              inputWrapperClassName={css.inputWithButtonWrapper}
              preset={globalCheckboxPreset.acceptTerms}
            >
              <RichText className={css.PrivacyPolicyLabel} render={privacyPolicyLabel} />
            </Checkbox>

            <SquareCta fill theme="backgroundBlack" aria-label={t("aria_submit")}>
              {isLoading ? <Spinner /> : t("form_signup")}
            </SquareCta>
          </LayoutForm>
        </>
      )}
    </PopinWrapper>
  )
}

const ForwardedWrapper = forwardRef<HTMLDivElement, BackInStockWrapperProps>(Wrapper)
export { ForwardedWrapper as Wrapper }
