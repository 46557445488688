import { useQuery } from "@tanstack/react-query"

import type { InferReturn } from "~/@types/generics"
import { fetchSbStory } from "~/lib/storyblok/utils/fetch-sb-story"

type TData = InferReturn<typeof fetchSbStory>

export function useFetchSbStoryQuery<TError = unknown>(
  [...params]: Parameters<typeof fetchSbStory>,
  options?: Parameters<typeof useQuery<TData, TError, TData, Parameters<typeof fetchSbStory>>>[2]
) {
  return useQuery<TData, TError, TData, Parameters<typeof fetchSbStory>>(
    [...params],
    async () => await fetchSbStory(...params),
    {
      // Set StaleTime to revalidate data after 15 minutes
      staleTime: 15 * 60 * 1000,
      ...options,
    }
  )
}
